import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import {
    AppBar,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import instance from "../../service/AxiosInterceptor";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function AddUserModal({ fetchUsers }: any) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        formik.resetForm();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const initialValues = {
        email: "",
        role: "",
    };

    const validationSchema = yup.object({
        email: yup.string().email().required(),
        role: yup.string().required(),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            instance
                .post("/users/invite", {
                    email: values.email,
                    role: values.role,
                })
                .then((response) => response.data)
                .then((data) => {
                    handleClose();
                    toast.success("User invited successfully!");
                    fetchUsers();
                })
                .catch((error) => {

                });
        },
    });

    const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
        formik;

    return (
        < >
            <Button
                onClick={handleClickOpen}
                variant="contained"
                // startIcon={<UserAddOutlined />}
                size="small"
                sx={{ width: { xs: '100%', sm: 140 } }}
                style={{ fontWeight: "bold" }}
            >
                Invite User
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
                fullWidth
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                            Invite User
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <FormikProvider value={formik}>
                    <Form noValidate onSubmit={handleSubmit} id="add-user-form">
                        <DialogContent dividers>
                            <Grid mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} my={3}>
                                <TextField
                                    type="email"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    variant="standard"
                                    id="email"
                                    name="email"
                                    label="User Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} mb={5} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel
                                        id="role-select-helper-label"
                                        variant="standard"
                                        error={touched.role && Boolean(errors.role)}
                                    >
                                        Role
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        margin="dense"
                                        labelId="role-select-helper-label"
                                        id="role-select-helper"
                                        name="role"
                                        value={values.role}
                                        label="Role"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.role && Boolean(errors.role)}
                                    >
                                        <MenuItem value="">
                                            <em>Select role</em>
                                        </MenuItem>
                                        <MenuItem value="USER">User</MenuItem>
                                        <MenuItem value="ACCOUNTANT">Accountant</MenuItem>
                                        <MenuItem value="ADMIN">Admin</MenuItem>
                                    </Select>
                                    {touched.role && Boolean(errors.role) && (
                                        <FormHelperText error>{errors.role}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </DialogContent>

                        <DialogActions >
                            <Button
                                sx={{ m: 1.5 }}
                                autoFocus
                                type="submit"
                                variant="contained"
                                size="small"
                            >
                                save
                            </Button>
                        </DialogActions>

                    </Form>
                </FormikProvider>
            </BootstrapDialog>

        </ >
    );
}
