import AddCardIcon from '@mui/icons-material/AddCard';
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Toolbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from "react";
import { useState } from 'react';
import * as yup from "yup";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Bolt } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function AddressRadiusModal(props: any) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        resetForm()
    };

    const [initialValues, setInitialValues] = useState({
        data: "",
        metadata: "50",
    });

    const validationSchema = yup.object({
        data: yup.string().required(),
        metadata: yup.string().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {
            props.handleSave(values)
            handleClose()
        },
    });

    const { errors, touched, values, handleSubmit, handleChange, handleBlur, resetForm } = formik;

    return (
        <>
            <Button
                size='small'
                onClick={handleClickOpen}
                variant="outlined"
                startIcon={
                    <AddCircleOutlineIcon />
                }
            >
                <Typography fontWeight="bold" fontSize={13}>
                    Add Address & Radius
                </Typography>
            </Button>
            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Address & Radius Details
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <FormikProvider value={formik} >
                        <Form noValidate id="address-radius-form" onSubmit={handleSubmit} >
                            <Grid container>
                                <Grid item xs={12} m={5}>
                                    <TextField
                                        type='address'
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={values.data}
                                        id="data"
                                        label="Address"
                                        placeholder="123 Main Street, City, State, ZIP"
                                        InputProps={{}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.data && Boolean(errors.data)}
                                        helperText={touched.data && errors.data}
                                    />
                                </Grid>
                                <Grid item xs={12} m={5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="radius-select-id">Radius</InputLabel>
                                        <Select
                                            variant="standard"
                                            margin="dense"
                                            size="small"
                                            labelId="radius-select-id"
                                            id="metadata"
                                            name='metadata'
                                            value={values.metadata}
                                            label="Radius"
                                            error={touched.metadata && Boolean(errors.metadata)}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">
                                                <em>Select radius range</em>
                                            </MenuItem>
                                            <MenuItem value={"10"}>Within 10 Miles</MenuItem>
                                            <MenuItem value={"25"}>Within 25 Miles</MenuItem>
                                            <MenuItem value={"50"}>Within 50 Miles</MenuItem>
                                            <MenuItem value={"100"}>Within 100 Miles</MenuItem>
                                        </Select>
                                        {touched.metadata && Boolean(errors.metadata) && (
                                            <FormHelperText error>{errors.metadata}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSubmit()}
                        variant='contained'
                        size='small'
                        sx={{}}
                    >save</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}
