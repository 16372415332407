import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";
import {
    AppBar,
    Box,
    Grid,
    TextField,
    Toolbar
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from '@mui/lab';
import instance from '../../../service/AxiosInterceptor';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function AddTargetContentModal(props: any) {

    const [open, setOpen] = React.useState(false);
    const [generating, setGenerating] = React.useState(false);

    const handleClickOpen = () => {
        formik.resetForm();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [initialValues, setInitialValues] = React.useState({
        header: "",
        description: "",
    });

    const validationSchema = yup.object({
        header: yup.string().max(30).required(),
        description: yup.string().max(90).required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            props.handleContentSave(values)
            handleClose()
            setInitialValues({ "header": "", "description": "" })
        },
    });

    const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
        formik;

    function handleAutoGenerate(event: any): void {
        setGenerating(true)
        instance
            .post("/ai/command?", {
                "command": "google-display-ad-generate-header-description",
                "context": props.businessDescription
            }).then((res) => {
                setInitialValues({
                    "header": res.data.header,
                    "description": res.data.description,
                })
            }).finally(() => {
                setGenerating(false)
            })
    }

    return (
        < >
            <Button
                onClick={handleClickOpen}
                variant='outlined'
                size='small'
                style={{ minWidth: "12rem", marginTop: "1.5rem" }}
                startIcon={<AddCircleOutlineIcon />}>
                <Typography fontWeight={"bold"} fontSize={13}>
                    Create Ad Content
                </Typography>
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
                fullWidth
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                            Ad Content
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <FormikProvider value={formik}>
                    <Form
                        noValidate onSubmit={handleSubmit} id="add-content-form">
                        <DialogContent  >
                            <Grid mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} my={3}>
                                <TextField
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    variant="standard"
                                    id="header"
                                    name="header"
                                    label="Header"
                                    value={values.header}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.header && Boolean(errors.header)}
                                    helperText={(touched.header && errors.header) || `${values.header?.length}/${30}`}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </DialogContent>

                        <DialogContent dividers>
                            <Grid mx={{ xs: 2, sm: 4, md: 6, lg: 10 }} my={3}>
                                <TextField
                                    type="text"
                                    multiline
                                    maxRows={4}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    variant="standard"
                                    id="description"
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={(touched.description && errors.description) || `${values.description?.length}/${90}`}
                                    inputProps={{ maxLength: 90 }}
                                />
                            </Grid>
                        </DialogContent>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <LoadingButton
                                loading={generating}
                                onClick={handleAutoGenerate}
                                sx={{ m: 1.5 }}
                                autoFocus
                                variant="contained"
                                size="small"
                                endIcon={<AutoFixHighIcon />}
                                disabled={props.businessDescription?.length === 0}
                            >
                                Auto Generate
                            </LoadingButton>
                            <Button
                                sx={{ m: 1.5 }}
                                autoFocus
                                type="submit"
                                variant="contained"
                                size="small"
                            >
                                Save
                            </Button>
                        </Box>
                    </Form>
                </FormikProvider>
            </BootstrapDialog>

        </ >
    );
}
