import {
  Chip,
  Divider,
  Grid,
  TableSortLabel,
  Typography,
  useTheme
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import instance from "../../service/AxiosInterceptor";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import EmptyTable from "../../util/EmptyTable";
import ViewReferralModal from "./ViewReferralModal";
import SearchReferral from "./SearchReferral";
import AddReferralModal from "./AddReferralModal";

interface Column {
  id:
  | "email"
  | "status"
  | "created_at"
  | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "email",
    label: "Email",
    minWidth: 25,
  },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "created_at", label: "Created At", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

interface ReferralData {
  referral_uuid: string;
  email: string;
  status: string;
  created_at: string;
}

export default function Referral() {
  const theme = useTheme();

  const [loading, setLoading] = React.useState(true);

  const [referrals, setReferrals] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [search, setSearch] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("name");

  const setSearchCriteria = (search: any, searchBy: any) => {
    setSearch(search);
    setSearchBy(searchBy);
  };

  const transformReferralStatusValue = (value: any) => {
    switch (value) {
      case "CAMPAIGN_STARTED":
        return (
          <Chip
            color="success"
            label={value}
            variant="outlined"
            size="small"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      case "EMAIL_SENT":
        return (
          <Chip
            color="warning"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      case "REGISTERED":
        return (
          <Chip
            color="primary"
            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      case "REDEEMED":
        return (
          <Chip

            label={value}
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold", color: "gray" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
      default:
        return (
          <Chip
            color="error"
            label="UNKNOWN"
            size="small"
            variant="outlined"
            style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
            sx={{ height: "20px", paddingTop: "1.5px" }}
          />
        );
    }
  };

  const fetchReferrals = React.useCallback(() => {
    instance
      .get("/referrals", {
        params: {
          page: page,
          size: rowsPerPage,
          order: order,
          sort: orderBy,
          [searchBy]: search,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        const newPage = page;
        setPage(newPage);
        setReferrals(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, rowsPerPage, order, orderBy, search, searchBy])

  React.useEffect(() => {
    fetchReferrals();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {
        loading ?
          <BackDrop open={loading} /> :
          <Grid container py={10} style={{}}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <Typography
                fontWeight={"bold"}
                sx={{ flex: "1 1 100%" }}
                id="tableTitle"
                component="div"
                px={2}
                py={0.75}
                border={"solid 0.5px silver"}
                bgcolor={theme.palette.primary.main}
                color={"white"}
              >
                Referrals
              </Typography>
              <Divider />
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                      <TableCell align="left" colSpan={5} size="small">
                        <SearchReferral setSearchCriteria={setSearchCriteria} />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                      <TableCell align="left" colSpan={6} size="small">
                        <AddReferralModal fetchReferrals={fetchReferrals} />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                      <TableCell align="left" colSpan={3} size="small">
                        <SearchReferral setSearchCriteria={setSearchCriteria} />
                      </TableCell>
                      <TableCell align="right" colSpan={columns.length} size="small">
                        <AddReferralModal fetchReferrals={fetchReferrals} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          size="small"
                          sortDirection={false}
                          key={column.id}
                          align={column.label === 'Action' ? 'right' : column.align}
                          sx={{
                            color: navy,
                            minWidth: column.minWidth,
                            fontVariant: "h4",
                            fontWeight: "bold",
                            display: {
                              xs: column.label === 'Type' ||
                                column.label === 'Status' ||
                                column.label === 'Created At' ||
                                column.label === 'Spend Limit' ||
                                column.label === 'Spent Status' ? 'none' : 'table-cell', sm: 'table-cell'
                            },
                            p: { xs: '6px', sm: '6px 16px' }
                          }}
                        >
                          {column.label}
                          {column.id !== "action" && (
                            <TableSortLabel
                              sx={{
                                "& .MuiTableSortLabel-icon": {
                                  color: "black !important",
                                },
                              }}
                              active={column.id === orderBy}
                              direction={order}
                              onClick={(event) => {
                                if (column.id === orderBy) {
                                  order === "asc"
                                    ? setOrder("desc")
                                    : setOrder("asc");
                                }
                                setOrderBy(column.id);
                              }}
                            ></TableSortLabel>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {referrals.length === 0 ? <EmptyTable
                        message="Your referrals will show up here. 
                        Start sending referrals & get $20 discount credit upon their ad campaign start." /> :
                        referrals.map((row: ReferralData) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.referral_uuid}
                            >
                              <TableCell className="text-overflow-table" sx={{ p: { xs: '6px', sm: '6px 16px' } }} key="email" size="small">
                                {row.email}
                              </TableCell>
                              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="status" size="small" >
                                <Typography variant="body2">
                                  {transformReferralStatusValue(row.status)}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} key="createdAt" size="small">
                                <Typography variant="subtitle2">
                                  {moment(row.created_at).format("YYYY-MM-DD HH:mm z")}
                                </Typography>
                              </TableCell>
                              <TableCell align="right" size="small" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                                <ViewReferralModal referral={row} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
      }
    </>
  );
}
