import { createContext, useMemo, useState } from "react";

export const StepperContext = createContext({});
export const StepperProvider = ({ children }: any) => {

  // const [campaignUuid, setCampaignUuid] = useState(null);

  const value =
    useMemo(
      () => ({
        // campaignUuid,
        // setCampaignUuid
      }),
      [
        // campaignUuid,
        // setCampaignUuid
      ]
    );

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
};

