import DrawIcon from '@mui/icons-material/Draw';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import _ from 'lodash';
import React from 'react';
const FacebookAdPreview = (props: any) => {

    const theme = useTheme()

    const [bannerOrder] = React.useState<boolean>(false);
    React.useEffect(() => {
    }, []);

    return (
        <>
            {
                <Grid container
                    mt={2}
                    justifyContent={"center"}
                    alignItems="center"
                >
                    <Card sx={{ width: { xs: '100%' } }}>
                        <CardHeader
                            title="Facebook Ad Preview"
                            titleTypographyProps={{
                                textAlign: "left",
                                fontSize: "1.1rem",
                                color: "white",
                            }}
                            subheader=""
                            subheaderTypographyProps={{ textAlign: "left" }}
                            style={{ backgroundColor: theme.palette.primary.main, height: "2.2rem" }}
                        />
                        <Divider style={{ margin: 10 }} />

                        <CardContent>
                            {
                                bannerOrder ?
                                    <Grid container justifyContent="center" alignItems="center" >
                                        <Grid item>
                                            <Paper style={{ padding: 16, textAlign: 'center' }}>
                                                <Typography sx={{ display: "flex", alignItems: "center" }}>
                                                    <DrawIcon style={{ marginRight: "5px" }} />
                                                    You ordered a banner design for your ad.
                                                    We are going to start working on it and will keep you posted.
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container spacing={2}>
                                        {
                                            _.filter(props.campaign?.ads[0]?.creatives,
                                                _.matches({ "type": "banner" }))
                                                .map((c: any, index: number) =>
                                                    <Grid item xs={3.5} key={index}>
                                                        <Card sx={{ border: "solid 0.5px silver" }}>
                                                            <CardContent>
                                                                <img
                                                                    src={c.path}
                                                                    alt={c.name}
                                                                    loading="lazy"
                                                                    style={{
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%',
                                                                        display: 'block',
                                                                        margin: 'auto'
                                                                    }}
                                                                />
                                                            </CardContent>
                                                            <CardActions>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )
                                        }
                                    </Grid>
                            }
                            <Divider style={{ margin: 20 }} />
                            <Grid container mt={2}>
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            props.handleBack()
                                        }}
                                    >
                                        <Typography fontWeight={"bold"} fontSize={13}>
                                            back
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            props.handleNext()
                                        }}
                                    >
                                        <Typography fontWeight={"bold"} fontSize={13}>
                                            confirm and continue
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid >
            }
        </>
    );
}

export default FacebookAdPreview