import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import {
  isLowercaseChar,
  isNumber,
  isSpecialChar,
  isUppercaseChar,
  minLength,
} from "../../util/PasswordValidation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { Formik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import instance from "../../service/AxiosInterceptor";
import axios from 'axios';

const ResetPassword = () => {

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  return (
    <Container component="main" style={{}}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          sx={{ width: { xs: '100%', sm: '50%' } }}>
          <CardHeader
            title="Reset Password"
            titleTypographyProps={{
              textAlign: "center",
              padding: 2,
              fontSize: "20px",
            }}
            subheader="Please fill out following form to reset your password"
            subheaderTypographyProps={{ textAlign: "center" }}
          />

          <CardContent>
            <Formik
              initialValues={{
                password: "",
                confirm: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required("Password is required")
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                  ),
                confirm: Yup.string()
                  .required("Confirm Password is required")
                  .test(
                    "confirm",
                    `Passwords don't match.`,
                    (confirm: string, yup: any) => yup.parent.password === confirm
                  ),
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ) => {
                axios
                  .put(
                    process.env.REACT_APP_ADB_BASE_API_URL +
                    "/reset-password",
                    {
                      token: queryParameters.get("token"),
                      password: values.password,
                    }
                  )
                  .then((response: any) => {
                    toast.success("Password reset successfully!")
                    navigate("/signin", { replace: true });
                  })
                  .catch((error: any) => {
                    toast.error(error.response.data.message || "Error occurred!")
                  });

              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid item py={3}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="password-password">Password</InputLabel>
                      <OutlinedInput
                        size="small"
                        placeholder="Enter Password"
                        id="password-password"
                        type={showNewPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {showNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText error id="password-password-helper">
                          {errors.password}
                        </FormHelperText>
                      )}

                      <Box sx={{ pt: 2 }}>
                        <Typography variant="body2">
                          Password Requirements
                        </Typography>
                        <List sx={{ p: 0, mt: 1 }} dense>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: minLength(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {minLength(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <RemoveCircleOutlineIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 8 characters" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isLowercaseChar(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isLowercaseChar(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <RemoveCircleOutlineIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 lower letter (a-z)" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isUppercaseChar(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isUppercaseChar(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <RemoveCircleOutlineIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 uppercase letter (A-Z)" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isNumber(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isNumber(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <RemoveCircleOutlineIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 number (0-9)" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                color: isSpecialChar(values.password)
                                  ? "success.main"
                                  : "inherit",
                              }}
                            >
                              {isSpecialChar(values.password) ? (
                                <CheckCircleOutlineIcon />
                              ) : (
                                <RemoveCircleOutlineIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="At least 1 special characters" />
                          </ListItem>
                        </List>
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid item py={3}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="password-confirm">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        size="small"
                        placeholder="Confirm Password"
                        id="password-confirm"
                        type={showConfirmPassword ? "text" : "password"}
                        value={values.confirm}
                        name="confirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{}}
                      />
                      {touched.confirm && errors.confirm && (
                        <FormHelperText error id="password-confirm-helper">
                          {errors.confirm}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        disabled={isSubmitting || Object.keys(errors).length !== 0}
                        type="submit"
                        variant="contained"
                        size="small"
                      >
                        submit
                      </Button>
                    </Stack>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Container >
  );
};

export default ResetPassword;
