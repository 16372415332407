import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography, styled, useTheme } from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { filesize } from "filesize";
import { Form, FormikProvider, useFormik } from "formik";
import _ from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import instance from "../../../service/AxiosInterceptor";
import { silver } from "../../../util/Color";
import AddWalmartContentModal from "./AddWalmartContentModal";
import WalmartAdWorkSampleModal from "./WalmartAdWorkSampleModal";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

export const WalmartAd = (props: any) => {

    const navigate = useNavigate();
    const theme = useTheme()

    const [autoGenerateBusinessDescription, setAutoGenerateBusinessDescription] = React.useState<any>(false);

    const [searchParams] = useSearchParams();
    const [adUuid, setAdUuid] = React.useState(null);
    const [content, setContent] = React.useState<any>([]);
    const [initialValues, setInitialValues] = useState({
        description: "",
        website: "",
        company: "",
        phone: "",
        address: "",
        bannerOrder: false

    });

    React.useEffect(() => {
        if (props.campaign.ads.length > 0) {
            setInitialValues({
                phone: props.campaign.ads[0]?.phone,
                company: props.campaign.ads[0]?.company,
                address: props.campaign.ads[0]?.address,
                website: props.campaign.ads[0]?.website,
                description: props.campaign.ads[0]?.description,
                bannerOrder: props.campaign.ads[0]?.banner_order
            })

            setCreative(props.campaign.ads[0]?.creatives)
            setContent(props.campaign.ads[0]?.content)
            setAdUuid(props.campaign.ads[0]?.ad_uuid)
        }
    }, []);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [uploadingLogo, setUploadingLogo] = React.useState(false);
    const [uploadingBanner, setUploadingBanner] = React.useState(false);
    const [creative, setCreative] = React.useState<any>([]);

    const validationSchema = yup.object({
        description: yup.string().required(),
        website: yup.string().required(),
        company: yup.string().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {
            upsertAd()
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, handleChange, handleBlur } = formik;

    const handleAutoGenerateBusinessDescription = (event: any) => {
        setAutoGenerateBusinessDescription(true)
        instance
            .post("/ai/command?", {
                "command": "generate-business-description",
                "context": values.website
            }).then((res) => {
                values.description = res.data.description
            }).finally(() => {
                setAutoGenerateBusinessDescription(false)
            })
    }

    const upsertAd = () => {
        instance.post("/ads", {
            ad_uuid: adUuid,
            campaign_uuid: searchParams.get("campaign_uuid"),
            description: values.description,
            website: values.website,
            company: values.company,
            content: content,
            creative: creative,
            banner_order: values.bannerOrder
        }).then(() => {
            props.handleNext()
        })
    }

    const handleUpload = (event: any, type: string) => {

        const file: any = event.target.files[0];

        let formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);

        const setUploading = (uploading: boolean) => {
            switch (type) {
                case "logo":
                    setUploadingLogo(uploading);
                    break;
                case "banner":
                    setUploadingBanner(uploading);
                    break;
                default:
                    throw new Error("Unsupported type!");
            }
        };

        setUploading(true)

        instance
            .post("/upload/creative", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                setCreative(() => [...creative, {
                    creative_uuid: res.data.creative_uuid,
                    path: res.data.path,
                    name: file.name,
                    size: file.size,
                    ext: file.type,
                    type: type
                }]);
            })
            .finally(() => {
                setUploading(false);
            });
    }

    const handelDeleteCreative = (item: any) => {
        instance.delete("/creatives?creative_uuid=" + item.creative_uuid)
            .then(() => {
                setCreative((creative: any) => creative.filter((c: any) => c.creative_uuid !== item.creative_uuid));
                toast.success("Deleted successfully!")
            })
    }

    const deleteContent = (input: any) => {

        const indexToRemove = _.findIndex(content, input);
        if (indexToRemove !== -1) {
            const newContent = _.cloneDeep(content);
            _.pullAt(newContent, indexToRemove);
            setContent(newContent);
        }
    }

    return (
        <>
            {
                <Grid container
                    mt={2}
                    justifyContent={"center"}
                    alignItems="center"
                >
                    <Card sx={{ width: { xs: '100%' } }}>
                        <CardHeader
                            title={"Walmart Ad"}
                            titleTypographyProps={{
                                textAlign: "left",
                                fontSize: "1.1rem",
                                // fontWeight: "bold",
                                color: "white",
                            }}
                            subheader=""
                            subheaderTypographyProps={{ textAlign: "left" }}
                            style={{ backgroundColor: theme.palette.primary.main, height: "2.2rem" }}
                        />

                        <CardContent>
                            <FormikProvider value={formik}>
                                <Form noValidate onSubmit={handleSubmit}>

                                    <Grid container >
                                        <Grid item xs={12} sm={4} m={1} >
                                            <Typography fontWeight={"bold"}>
                                                Website
                                            </Typography>
                                            <Typography variant="subtitle2">
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={7} m={1} >
                                            <TextField
                                                id="website"
                                                name="website"
                                                value={values.website}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.website && Boolean(errors.website)}
                                                helperText={touched.website && errors.website}
                                                label="Website address"
                                                fullWidth
                                                size="small"
                                                variant="standard"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Divider style={{
                                        margin: 10
                                    }} />

                                    <Grid container >
                                        <Grid item xs={12} sm={4} m={1} >
                                            <Typography fontWeight={"bold"}>
                                                Description
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={7} m={1} >
                                            <TextField
                                                margin="none"
                                                fullWidth
                                                label="Product Description"
                                                placeholder="Tell us about the product you want to advertise, highlight any unique features or benefits that will interest your audience, and mention any statistics, reviews, or ratings."
                                                multiline
                                                id="description"
                                                name="description"
                                                value={values.description}
                                                rows={6}
                                                defaultValue=""
                                                variant="standard"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.description && Boolean(errors.description)}
                                                helperText={touched.description && errors.description}
                                                InputProps={{
                                                    style: {
                                                        ...theme.typography.subtitle2,
                                                    },
                                                }}
                                            />
                                            <LoadingButton
                                                style={{ minWidth: "12rem", marginTop: "1.5rem" }}
                                                loading={autoGenerateBusinessDescription}
                                                onClick={handleAutoGenerateBusinessDescription}
                                                autoFocus
                                                variant="outlined"
                                                size="small"
                                                startIcon={<AutoFixHighIcon />}
                                                disabled={values.website?.length === 0}
                                            >
                                                <Typography fontWeight={"bold"} letterSpacing={0} fontSize={13} >
                                                    Auto Generate
                                                </Typography>
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>

                                    <Divider style={{
                                        margin: 10
                                    }} />

                                    <Grid container >
                                        <Grid item xs={12} sm={4} m={1} >
                                            <Typography fontWeight={"bold"}>
                                                Company Details
                                            </Typography>
                                            <Typography variant="subtitle2">
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={7} m={1} >
                                            <Stack direction={{ xs: "column", sm: "row" }}>
                                                <TextField
                                                    id="company"
                                                    name="company"
                                                    value={values.company}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={touched.company && Boolean(errors.company)}
                                                    helperText={touched.company && errors.company}
                                                    label="Company name"
                                                    fullWidth
                                                    size="small"
                                                    variant="standard"
                                                />
                                                <TextField
                                                    id="phone"
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={touched.phone && Boolean(errors.phone)}
                                                    helperText={touched.phone && errors.phone}
                                                    label="Phone number"
                                                    fullWidth
                                                    size="small"
                                                    variant="standard"
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Divider style={{
                                        margin: 10
                                    }} />

                                    <Grid container >
                                        <Grid item xs={12} sm={4} m={1} >
                                            <Typography fontWeight={"bold"}>
                                                Ad Content
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                Add up to 5 items
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={7} m={1}>
                                            <AddWalmartContentModal
                                                businessDescription={values.description}
                                                handleContentSave={({ header, description }: any) => {
                                                    setContent([...content, { header, description }]);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        content?.length !== 0 &&
                                        <Grid container>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: silver }}>
                                                        <TableRow>
                                                            <TableCell size="small" style={{ width: '5%' }}>#</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '20%' }}>Header</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '65%' }}>Description</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '10%' }}>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {content.map((ad: any, index: number) => (
                                                            <TableRow
                                                                key={ad.header}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell size="small" align="left">{index + 1}</TableCell>
                                                                <TableCell size="small" component="th" scope="row">
                                                                    {ad.header}
                                                                </TableCell>
                                                                <TableCell size="small" align="left">{ad.description}</TableCell>
                                                                <TableCell size="small" align="left">
                                                                    <EditIcon className='edit-icon' />
                                                                    <DeleteOutlineIcon className='delete-icon'
                                                                        onClick={
                                                                            () => deleteContent(ad)
                                                                        } />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    }
                                    <Divider style={{
                                        margin: 10
                                    }} />

                                    <Grid container >
                                        <Grid item xs={12} sm={4} m={1} >
                                            <Typography fontWeight={"bold"}>
                                                Logo
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                Recommended size: 1200 x 1200 px
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={7} m={1} my={2}>
                                            <LoadingButton
                                                loading={uploadingLogo}
                                                size='small'
                                                component="label"
                                                role={undefined}
                                                variant="outlined"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadIcon />}
                                                onChange={(event) => handleUpload(event, "logo")}
                                                style={{ minWidth: "12rem" }}
                                            >
                                                <Typography fontWeight={"bold"} letterSpacing={0} fontSize={13} >
                                                    Upload Logo
                                                </Typography>
                                                <VisuallyHiddenInput type="file" />
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                    {
                                        _.some(creative, _.matches({ "type": "logo" })) && <Grid container>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: silver }}>
                                                        <TableRow>
                                                            <TableCell size="small" style={{ width: '5%' }}>#</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '20%' }}>Image</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '50%' }}>File</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '15%' }}>Size</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '10%' }}>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {_.filter(creative, _.matches({ "type": "logo" })).map((u: any, index: number) => (
                                                            <TableRow
                                                                key={u.file}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell size="small" align="left">{index + 1}</TableCell>
                                                                <TableCell size="small" align="left">
                                                                    <img
                                                                        alt=''
                                                                        style={{ width: '30px' }}
                                                                        src={u.path}
                                                                    />
                                                                </TableCell>
                                                                <TableCell size="small" component="th" scope="row">
                                                                    {u.name}
                                                                </TableCell>
                                                                <TableCell size="small" align="left">
                                                                    {filesize(u.size)}
                                                                </TableCell>
                                                                <TableCell size="small" align="left">
                                                                    <DeleteOutlineIcon
                                                                        className='delete-icon'
                                                                        onClick={() => handelDeleteCreative(u)}>
                                                                    </DeleteOutlineIcon>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    }
                                    <Divider style={{
                                        margin: 10
                                    }} />
                                    <Grid container >
                                        <Grid item xs={12} sm={4} m={1} >
                                            <Typography fontWeight={"bold"}>
                                                Ad Banners
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                Recommended image size: 1080 x 1080 px
                                                <br />
                                                Recommended image format: JPG, JPEG, PNG
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={7} m={1} my={2}>
                                            <LoadingButton
                                                loading={uploadingBanner}
                                                size='small'
                                                component="label"
                                                role={undefined}
                                                variant="outlined"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadIcon />}
                                                onChange={(event) => handleUpload(event, "banner")}
                                                style={{ minWidth: "12rem" }}
                                            >
                                                <Typography fontWeight={"bold"} letterSpacing={0} fontSize={13} >
                                                    Upload Banner
                                                </Typography>
                                                <VisuallyHiddenInput type="file" />
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                    {
                                        _.some(creative, _.matches({ "type": "banner" })) && <Grid container>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: silver }}>
                                                        <TableRow>
                                                            <TableCell size="small" style={{ width: '5%' }}>#</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '20%' }}>Image</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '50%' }}>File</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '15%' }}>Size</TableCell>
                                                            <TableCell size="small" align="left" style={{ width: '10%' }}>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {_.filter(creative, _.matches({ "type": "banner" })).map((c: any, index: number) => (
                                                            <TableRow
                                                                key={c.file}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell size="small" align="left">{index + 1}</TableCell>
                                                                <TableCell size="small" align="left">
                                                                    <img
                                                                        alt="logo"
                                                                        style={{ width: '30px' }}
                                                                        src={c.path}
                                                                    />
                                                                </TableCell>
                                                                <TableCell size="small" component="th" scope="row">
                                                                    {c.name}
                                                                </TableCell>
                                                                <TableCell size="small" align="left">
                                                                    {filesize(c.size)}
                                                                </TableCell>
                                                                <TableCell size="small" align="left">
                                                                    <DeleteOutlineIcon
                                                                        className='delete-icon'
                                                                        onClick={() => handelDeleteCreative(c)}
                                                                    ></DeleteOutlineIcon>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    }

                                    <Grid container>
                                        <Grid item mx={1}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={values.bannerOrder}
                                                        id="bannerOrder"
                                                        name="bannerOrder"
                                                        value={values.bannerOrder}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                }
                                                    label={
                                                        <Typography>
                                                            Check the box if you would like us to design ad banners for you.
                                                        </Typography>
                                                    } />
                                            </FormGroup>
                                            <WalmartAdWorkSampleModal />
                                        </Grid>
                                    </Grid>

                                    <Divider style={{ margin: 10 }} />

                                    <Grid container mt={2} spacing={2}>
                                        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button
                                                autoFocus
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    props.handleBack()
                                                }}
                                            >
                                                <Typography fontWeight={"bold"} fontSize={13}>
                                                    back
                                                </Typography>
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    navigate("/campaigns", { replace: true })
                                                }}
                                                style={{ marginRight: "20px" }}
                                            >
                                                <Typography fontSize={13}>
                                                    save as draft
                                                </Typography>
                                            </Button>
                                            <LoadingButton
                                                loading={isSubmitting}
                                                autoFocus
                                                type="submit"
                                                variant="contained"
                                                size="small"
                                            >
                                                <Typography fontWeight={"bold"} fontSize={13}>
                                                    save and continue
                                                </Typography>
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </FormikProvider>
                        </CardContent>
                    </Card >
                </Grid >
            }
        </>
    );
}

