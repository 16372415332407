import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Container, Box, TextField, Button, Typography, Stack } from '@mui/material';
import Link from "@mui/material/Link";
import { useNavigate } from 'react-router-dom';

interface SignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
}

const Signup: React.FC = () => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
  });

  const formik = useFormik<SignupFormData>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle successful form submission
      console.log('Form submitted:', values);
      alert('Signup successful!');
    },
  });

  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          {/* Email Field */}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
          />
          {formik.touched.email && formik.errors.email ? (
            <Typography color="error" variant="body2">
              {formik.errors.email}
            </Typography>
          ) : null}

          {/* Password Field */}
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
          />
          {formik.touched.password && formik.errors.password ? (
            <Typography color="error" variant="body2">
              {formik.errors.password}
            </Typography>
          ) : null}

          {/* Confirm Password Field */}
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <Typography color="error" variant="body2">
              {formik.errors.confirmPassword}
            </Typography>
          ) : null}

          {/* Submit Button */}
          <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
            <Button
              type="submit"
              size="small"
              variant="contained"
              sx={{ mb: 2 }}
              style={{ backgroundColor: '#1976d2' }}
            >
              Sign Up
            </Button>
            {/* Already have an account? */}
            <Typography variant="body2" align="center">
              Already have an account?{' '}
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/signin')}
                variant="body2"
              >
                Sign In
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default Signup;