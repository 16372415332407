
import { Divider, Grid, TableSortLabel, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import instance from "../../service/AxiosInterceptor";
import { transformValue } from "../../transformer/TransaformIntegrationStatus";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import EmptyTable from "../../util/EmptyTable";
import FacebookLoginButton from "../Facebook/FacebookLoginButton";
import GoogleLoginButton from "../Google/GoogleLoginButton";
import GoogleSignIn from "../Google/GoogleSignIn";
interface Column {
    id: "channel" | "status" | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    {
        id: "channel",
        label: "Channel",
        minWidth: 100,
    },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
];

export interface IntegrationData {
    integration_uuid: string;
    channel: string;
    status: string;
}

export default function IntegrationTable() {

    const theme = useTheme();

    const [integrations, setIntegrations] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState("createdAt");

    const [backdrop, setBackdrop] = React.useState(true);

    const fetchIntegrations = React.useCallback(
        () => {
            setBackdrop(true)
            instance
                .get("/integrations", {
                    params: {
                        page: page,
                        size: rowsPerPage,
                        order: order,
                        sort: orderBy,
                    },
                })
                .then((res: any) => {
                    const newPage = page;
                    setPage(newPage);
                    setIntegrations(res.data.content);
                    setTotalElements(res.data.totalElements);
                })
                .finally(() => {
                    setBackdrop(false);
                });
        }, [order, orderBy, page, rowsPerPage]
    )

    React.useEffect(() => {
        fetchIntegrations();
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const sort = (integrations: IntegrationData[]): IntegrationData[] => {
        return integrations.slice().sort((a, b) => a.channel.localeCompare(b.channel));
    }

    return (
        <>
            {backdrop ? (
                <BackDrop open={backdrop} />
            ) : (
                <Grid container>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <Typography
                            fontWeight={"bold"}
                            sx={{ flex: "1 1 100%" }}
                            id="tableTitle"
                            component="div"
                            px={2}
                            py={0.75}
                            border={"solid 0.5px silver"}
                            bgcolor={theme.palette.primary.main}
                            color={"white"}
                        >
                            Integrations
                        </Typography>
                        <Divider />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                size="small"
                                                sortDirection={false}
                                                key={column.id}
                                                align={column.label === 'Action' ? 'right' : column.align}
                                                sx={{
                                                    color: navy,
                                                    minWidth: column.minWidth,
                                                    fontVariant: "h4",
                                                    fontWeight: "bold",
                                                    backgroundColor: "",
                                                    display: {
                                                        xs: column.label === 'Role'
                                                            || column.label === 'Status'
                                                            || column.label === 'Created At' ? 'none' : 'table-cell', md: 'table-cell'
                                                    },
                                                }}
                                            >
                                                {column.label}
                                                {column.id !== "action" && (
                                                    <TableSortLabel
                                                        sx={{
                                                            "& .MuiTableSortLabel-icon": {
                                                                color: "black !important",
                                                            },
                                                        }}
                                                        active={column.id === orderBy}
                                                        direction={order}
                                                        onClick={(event) => {
                                                            if (column.id === orderBy) {
                                                                order === "asc"
                                                                    ? setOrder("desc")
                                                                    : setOrder("asc");
                                                            }
                                                            setOrderBy(column.id);
                                                        }}
                                                    ></TableSortLabel>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {integrations.length === 0 ? <EmptyTable /> :
                                        sort(integrations).map((row: IntegrationData) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.channel}
                                                >
                                                    <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="channel"  >
                                                        {row.channel}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="status">
                                                        {transformValue(row.status)}
                                                    </TableCell>
                                                    <TableCell size="small" align="right" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                                                        {row.channel === "FACEBOOK" && (
                                                            <FacebookLoginButton
                                                                integrationUuid={row.integration_uuid}
                                                                status={row.status}
                                                                fetchIntegrations={fetchIntegrations}
                                                            />
                                                        )}
                                                        {row.channel === "GOOGLE" && (
                                                            <GoogleLoginButton
                                                                integrationUuid={row.integration_uuid}
                                                                status={row.status}
                                                                fetchIntegrations={fetchIntegrations}
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            )}
        </>
    );
}
