import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useCallback, useState } from "react";
import {
    createButton
} from "react-social-login-buttons";
import { toast } from "react-toastify";
import {
    LoginSocialFacebook
} from "reactjs-social-login";
import instance from "../../service/AxiosInterceptor";

const REDIRECT_URI = "http://localhost:3000/account/login";

const FacebookLoginButton = (props: any) => {
    const [provider, setProvider] = useState("");
    const [profile, setProfile] = useState<any>();

    const onLoginStart = useCallback(() => {
        console.log("login start");
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider("");
        console.log("logout success");
    }, []);

    return (
        (props.status !== "CONNECTED") ?
            <LoginSocialFacebook
                appId={process.env.REACT_APP_ADB_FACEBOOK_APP_ID ?? ''}
                scope="read_insights,pages_show_list,business_management,pages_read_engagement,public_profile,pages_manage_ads,ads_read,ads_management"
                // fieldsProfile={
                //     'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                // }
                onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                // redirect_uri={REDIRECT_URI}
                onResolve={(response: any) => {
                    console.log(response)
                    setProfile(response.data)
                    instance
                        .post("/facebook/persist-access-token", {
                            token: response.data?.accessToken,
                            name: response.data?.name,
                            expire_in: response.data?.expiresIn,
                            picture_url: response.data?.picture.data.url,
                            data_access_expiration_time: response.data?.data_access_expiration_time
                        })
                        .then((data) => {
                            toast.success("Facebook account connected!");
                            props.fetchIntegrations()
                        })
                        .catch((error: any) => {
                        });
                }}
                onReject={err => {
                    toast.error(JSON.stringify(err))
                }}
            >
                <AddCircleOutlineIcon className='add-icon' />
            </LoginSocialFacebook>
            :
            <RemoveCircleOutlineIcon className='remove-icon' onClick={() => {
                setProfile(null)
                instance
                    .delete("/integrations?uuid=" + props.integrationUuid)
                    .then((response) => {
                        props.fetchIntegrations();
                        toast.success("Facebook account disconnected!");
                    })
                    .finally(() => {
                    });
            }} />
    );
};

export default FacebookLoginButton;
