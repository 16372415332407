import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, CardHeader, Chip, DialogActions, Divider, Grid, InputAdornment, ListItem, ListItemButton, ListItemText, Stack, TextField, Typography, useTheme } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Form, FormikProvider, useFormik } from "formik";
import * as React from 'react';
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as yup from "yup";
import { ChannelEnum } from '../../enum/ChannelEnum';
import instance from "../../service/AxiosInterceptor";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import AddressRadiusTable from './AddressRadius/AddressRadiusTable';
import CountryStateCityTable from './CountryStateCity/CountryStateCityTable';
import { LocationTypeEnum } from '../../enum/LocationTypeEnum';

const Campaign = (props: any) => {

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const theme = useTheme();

    const [initialValues, setInitialValues] = useState({
        name: "",
        goal: "sell",
        channel: ChannelEnum.GOOGLE_SEARCH,
        targetLocationBy: "country-state-city",
    });

    const [needle, setNeedle] = useState('');
    const [backdrop, setBackdrop] = useState(false);
    const [debouncedNeedleValue] = useDebounce(needle, 250);
    const [geos, setGeos] = React.useState<any>([]);
    const [selectedGeos, setSelectedGeos] = React.useState<any>([]);
    const [locations, setLocations] = React.useState<any>([]);

    React.useEffect(() => {
        debouncedNeedleValue.length > 0 &&
            instance
                .get("/geo/query?needle=" + debouncedNeedleValue)
                .then((res) => {
                    setGeos(res.data);
                })
    }, [debouncedNeedleValue])


    const validationSchema = yup.object({
        name: yup.string().required(),
    });

    const upsertCampaign = () => {
        instance.post("/campaign", {
            campaign_uuid: searchParams.get("campaign_uuid"),
            name: values.name,
            location_type: values.targetLocationBy,
            goal: values.goal,
            channel: values.channel,
            locations: locations
        }).then((res) => {
            props.handleNext()
        })
    }

    const getCampaign = () => {
        setBackdrop(true)
        instance.get("/campaign?campaign_uuid=" + searchParams.get("campaign_uuid"))
            .then(res => {
                setInitialValues({
                    name: res.data.name,
                    goal: res.data.goal || 'sell',
                    channel: res.data.channel || ChannelEnum.GOOGLE_SEARCH,
                    targetLocationBy: res.data.location_type || LocationTypeEnum.COUNTRY_STATE_CITY,
                })

                res.data.locations != null && setLocations(() => [...locations, ...res.data.locations]);
            }).finally(() => {
                setBackdrop(false)
            })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {
            upsertCampaign()
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, handleChange, handleBlur, setValues } = formik;

    function addGeo(geo: any): void {
        setSelectedGeos([...selectedGeos, geo]);
        setGeos([])
    }

    function removeGeo(geo: any): void {
        const updatedSelectedGeos = selectedGeos.filter((id: any) => id !== geo.id);
        setSelectedGeos(updatedSelectedGeos);
    }

    React.useEffect(() => {
        getCampaign()
    }, [])

    const handleSave = (input: any) => {
        setLocations(() => [...locations, input]);
    }

    const handleRemove = (index: any) => {
        setLocations(() => locations.filter((_: any, i: any) => i !== index));
    }

    return (
        <>
            {
                backdrop ? <BackDrop open={true} /> :
                    <Grid container
                        mt={2}
                        justifyContent={"center"}
                        alignItems="center"
                    >
                        <Card sx={{ width: { xs: '100%' } }}>
                            <CardHeader
                                title={"Campaign Setting"}
                                titleTypographyProps={{
                                    textAlign: "left",
                                    fontSize: "1.1rem",
                                    color: "white",
                                }}
                                subheader={""}
                                subheaderTypographyProps={{ textAlign: "left" }}
                                style={{ backgroundColor: theme.palette.primary.main, height: "2.2rem" }}

                            />
                            <Divider style={{ margin: "20" }} />
                            <CardContent>
                                <FormikProvider value={formik}>
                                    <Form noValidate onSubmit={handleSubmit} id="add-user-form">

                                        <Grid container>
                                            <Grid item xs={4} m={1}>
                                                <Typography fontWeight={"bold"} color={navy}>
                                                    Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} m={0} p={0}>
                                                <Box
                                                    sx={{
                                                        width: 500,
                                                        maxWidth: '100%',
                                                        margin: "0",
                                                        padding: "0"
                                                    }
                                                    }
                                                >
                                                    <TextField
                                                        size="small"
                                                        variant="standard"
                                                        id="name"
                                                        name="name"
                                                        label="Campaign Name"
                                                        placeholder="e.g. Summer Sale Campaign"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.name && Boolean(errors.name)}
                                                        helperText={touched.name && errors.name}
                                                        fullWidth
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Divider style={{
                                            margin: 10
                                        }} />

                                        <Grid container>
                                            <Grid item xs={12} md={4} m={1}>
                                                <Typography fontWeight={"bold"} color={navy}>
                                                    Channel
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <RadioGroup
                                                        aria-labelledby="channel-radio-buttons-group-label"
                                                        name="channel"
                                                        id='channel'
                                                        value={values.channel}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <Grid container>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.GOOGLE_SEARCH} control={<Radio />} label="Google Search" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}  >
                                                                <FormControlLabel value={ChannelEnum.GOOGLE_DISPLAY} control={<Radio />} label="Google Display" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.FACEBOOK} control={<Radio />} label="Facebook" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.INSTAGRAM} control={<Radio />} label="Instagram" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.TIKTOK} control={<Radio />} label="TikTok" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.LINKEDIN} control={<Radio />} label="LinkedIn" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.ETSY} control={<Radio />} label="Etsy" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.AMAZON} control={<Radio />} label="Amazon" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.SNAPCHAT} control={<Radio />} label="Snapchat" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.WALMART} control={<Radio />} label="Walmart" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.INSTACART} control={<Radio />} label="Instacart" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.TARGET} control={<Radio />} label="Target" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.REDDIT} control={<Radio />} label="Reddit" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.PINTEREST} control={<Radio />} label="Pinterest" />
                                                            </Grid>
                                                            <Grid xs={12} sm={6} md={4}>
                                                                <FormControlLabel value={ChannelEnum.YOUTUBE} control={<Radio />} label="Youtube" />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                    {formik.touched.channel && formik.errors.channel ? (
                                                        <div>{formik.errors.channel}</div>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Divider style={{
                                            margin: 10
                                        }} />

                                        <Grid container>
                                            <Grid item xs={12} md={4} m={1}>
                                                <Typography fontWeight={"bold"} color={navy}>
                                                    Goal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <RadioGroup
                                                        aria-labelledby="goal-radio-buttons-group-label"
                                                        name="goal"
                                                        id='goal'
                                                        value={values.goal}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <FormControlLabel value="sell" control={<Radio />} label="Sell product or services" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <FormControlLabel value="collect-signups" control={<Radio />} label="Collect signups" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <FormControlLabel value="brand-awareness" control={<Radio />} label="Build brand awareness" />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            {/* <Grid item xs={4} m={1}>
                                                <Stack>
                                                    <Typography fontWeight="bold" color={navy}>
                                                        Target Locations
                                                    </Typography>
                                                    {selectedGeos?.map((geo: any, index: any) => (
                                                        <Chip
                                                            deleteIcon={<DeleteIcon color='error' fontSize='small' />}
                                                            color="primary"
                                                            variant="outlined"
                                                            label={
                                                                <Typography variant='subtitle2'>
                                                                    {geo.name}
                                                                </Typography>
                                                            } onDelete={() => removeGeo(geo)} style={{
                                                                maxWidth: "10rem", marginTop: "1rem"
                                                            }} key={geo.name} />
                                                    ))}
                                                </Stack>
                                            </Grid> */}
                                            {/* <Grid item xs={7}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <RadioGroup
                                                        aria-labelledby="location-radio-buttons-group-label"
                                                        name="location"
                                                        id='location'
                                                        value={values.location}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <FormControlLabel value="all" control={<Radio />} label="All countries" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControlLabel value="usa-and-canada" control={<Radio />} label="United States and Canada" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControlLabel value="usa" control={<Radio />} label="United States" />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <FormControlLabel
                                                                    value="other" control={<Radio />} label="Other countries" />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                {
                                                                    (values.location === "other") &&
                                                                    <TextField
                                                                        value={needle}
                                                                        fullWidth
                                                                        id="outlined-basic"
                                                                        label="Country"
                                                                        placeholder="Search by country name"
                                                                        variant="standard"
                                                                        size="small"
                                                                        style={{
                                                                            marginTop: "10px"
                                                                        }}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <SearchIcon />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        onChange={(event) => {
                                                                            setNeedle(event.target.value)
                                                                        }}
                                                                    />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            ((values.location === "other") && debouncedNeedleValue.length > 0) &&
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                                        <nav aria-label="secondary mailbox folders">
                                                                            {geos?.map((geo: any, index: any) => (
                                                                                <>
                                                                                    <ListItem
                                                                                        disablePadding
                                                                                        secondaryAction={
                                                                                            <AddCircleOutlineIcon
                                                                                                color='primary'
                                                                                                onClick={() => addGeo(geo)} />
                                                                                        }
                                                                                        key={index}
                                                                                    >
                                                                                        <ListItemButton >
                                                                                            <ListItemText primary={geo.name} />
                                                                                        </ListItemButton>
                                                                                    </ListItem>
                                                                                    <Divider variant="middle" />
                                                                                </>
                                                                            ))}
                                                                        </nav>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid> */}
                                        </Grid>

                                        <Divider style={{
                                            margin: 10
                                        }} />

                                        <Grid container>
                                            <Grid item xs={12} md={4} m={1}>
                                                <Stack>
                                                    <Typography fontWeight="bold" color={navy}>
                                                        Target Locations By
                                                    </Typography>
                                                    {selectedGeos?.map((geo: any, index: any) => (
                                                        <Chip
                                                            deleteIcon={<DeleteIcon color='error' fontSize='small' />}
                                                            color="primary"
                                                            variant="outlined"
                                                            label={
                                                                <Typography variant='subtitle2'>
                                                                    {geo.name}
                                                                </Typography>
                                                            } onDelete={() => removeGeo(geo)} style={{
                                                                maxWidth: "10rem", marginTop: "1rem"
                                                            }} key={geo.name} />
                                                    ))}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <RadioGroup
                                                        aria-labelledby="target-location-by-radio-buttons-group-label"
                                                        name="targetLocationBy"
                                                        id='targetLocationBy'
                                                        value={values.targetLocationBy}
                                                        onChange={(event) => {
                                                            formik.handleChange(event);
                                                            setLocations([]);
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <FormControlLabel
                                                                    value={LocationTypeEnum.COUNTRY_STATE_CITY}
                                                                    control={<Radio />}
                                                                    label="Country, State, or City" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <FormControlLabel
                                                                    value={LocationTypeEnum.ADDRESS_RADIUS}
                                                                    control={<Radio />}
                                                                    label="Address and Radius" />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {
                                            (values.targetLocationBy === LocationTypeEnum.ADDRESS_RADIUS) &&
                                            <AddressRadiusTable
                                                locations={locations}
                                                handleSave={(input: any) => {
                                                    handleSave(input)
                                                }}
                                                handleRemove={(index: any) => {
                                                    handleRemove(index)
                                                }}
                                            />
                                        }
                                        {
                                            (values.targetLocationBy === LocationTypeEnum.COUNTRY_STATE_CITY) &&
                                            <CountryStateCityTable
                                                locations={locations}
                                                handleSave={(input: any) => {
                                                    console.log(input)
                                                    handleSave(input)
                                                }}
                                                handleRemove={(index: any) => {
                                                    handleRemove(index)
                                                }}
                                            />
                                        }


                                        <Divider style={{
                                            margin: 10
                                        }} />

                                        <DialogActions >
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    navigate("/campaigns", { replace: true })
                                                }}
                                            >
                                                <Typography fontSize={13}>
                                                    save as draft
                                                </Typography>
                                            </Button>
                                            <LoadingButton
                                                loading={isSubmitting}
                                                autoFocus
                                                type="submit"
                                                variant="contained"
                                                size="small"
                                            >
                                                <Typography fontWeight={"bold"} fontSize={13}>
                                                    save and continue
                                                </Typography>
                                            </LoadingButton>
                                        </DialogActions>
                                    </Form>
                                </FormikProvider>
                            </CardContent>
                        </Card>
                    </Grid >
            }
        </>
    );
}

export default Campaign