import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Button, Chip, Toolbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { convertCentsToDollars } from "../../util/Util";
import Stripe from "../Stripe/Stripe";
import AddCardIcon from '@mui/icons-material/AddCard';
import AddPaymentMethodStripe from "../Stripe/AddPaymentMethodStripe";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function AddPaymentMethodModal(props: any) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                onClick={handleClickOpen}
                variant="outlined"
                startIcon={<AddCardIcon />}
                size="small"
            >
                <Typography fontWeight={"bold"} fontSize={13} >
                    Add payment method
                </Typography>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
                scroll={'paper'}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Payment
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <Typography sx={{ p: 3 }}>
                        Please provide your card information for payment.
                    </Typography>
                    {/* <Stripe payment_uuid={props.payment_uuid} ></Stripe> */}
                    <AddPaymentMethodStripe />
                </DialogContent>

                {/* <DialogActions>

                </DialogActions> */}
            </BootstrapDialog>
        </>
    );
}
