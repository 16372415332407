export const convertCentsToDollars = (cents: any) => {
    return "$" + (cents / 100).toFixed(2);
};

export const centToUsd = (cent: any) => {
    return parseFloat((cent / 100).toFixed(2));
};

export const usdToCent = (usd: number) => {
    return Math.round(usd * 100);
}

export function calculatePercentage(percentage: number, total: number) {
    if (isNaN(percentage) || isNaN(total)) {
        throw new Error('Both percentage and total should be numbers');
    }
    return (percentage / 100) * total;
}