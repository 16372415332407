import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const SetupForm = (props: any) => {

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [loading, setLoading] = useState<any>(null);

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
            setErrorMessage(null);
        }
    }, [errorMessage]);

    const url = () => {
        if (!_.includes(window.location.href, "/settings")) {
            return window.location.href + `&step=3`
        }
        return window.location.href

    }

    const handleSubmit = async (event: any) => {

        setLoading(true)

        event.preventDefault();
        if (!stripe || !elements) {
            setLoading(false)
            return null;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: url(),
            },
        });

        if (error) {
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setLoading(false)
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <LoadingButton
                size='large'
                type='submit'
                loading={loading}
                disabled={!stripe} style={{
                    backgroundColor: "#1976d2"
                }}>
                save payment method
            </LoadingButton>
            {/* {errorMessage && (toast.error(errorMessage), setErrorMessage(null))} */}
        </form>
    )
};

export default SetupForm;