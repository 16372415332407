import { Chip, Stack, Typography } from "@mui/material";
import { BiLogoEtsy } from "react-icons/bi";
import { FaAmazon, FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaPinterest, FaReddit, FaSnapchat, FaTiktok, FaYoutubeSquare } from "react-icons/fa";
import { SiInstacart, SiTarget } from "react-icons/si";
import { TbBrandWalmart } from "react-icons/tb";

export const transformChannelValue = (value: any) => {
    switch (value) {
        case "GOOGLE_SEARCH":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaGoogle size="16" />
                    <Typography variant="body2">
                        Search
                    </Typography>
                </Stack>
            );
        case "GOOGLE_DISPLAY":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaGoogle size="16" />
                    <Typography variant="body2">
                        Display
                    </Typography>
                </Stack>
            );
        case "FACEBOOK":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaFacebook size="18" />
                    <Typography variant="body2">
                        Facebook
                    </Typography>
                </Stack>
            );
        case "INSTAGRAM":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaInstagram size="18" />
                    <Typography variant="body2">
                        Instagram
                    </Typography>
                </Stack>
            );
        case "LINKEDIN":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaLinkedin size="18" />
                    <Typography variant="body2">
                        LinkedIn
                    </Typography>
                </Stack>
            );
        case "TIKTOK":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaTiktok size="18" />
                    <Typography variant="body2">
                        TikTok
                    </Typography>
                </Stack>
            );
        case "AMAZON":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaAmazon size="18" />
                    <Typography variant="body2">
                        Amazon
                    </Typography>
                </Stack>
            );
        case "ETSY":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <BiLogoEtsy size="18" />
                    <Typography variant="body2">
                        Etsy
                    </Typography>
                </Stack>
            );
        case "WALMART":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <TbBrandWalmart size="18" />
                    <Typography variant="body2">
                        Walmart
                    </Typography>
                </Stack>
            );
        case "SNAPCHAT":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaSnapchat size="18" />
                    <Typography variant="body2">
                        Snapchat
                    </Typography>
                </Stack>
            );
        case "INSTACART":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <SiInstacart size="18" />
                    <Typography variant="body2">
                        Instacart
                    </Typography>
                </Stack>
            );
        case "TARGET":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <SiTarget size="18" />
                    <Typography variant="body2">
                        Target
                    </Typography>
                </Stack>
            );
        case "REDDIT":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaReddit size="18" />
                    <Typography variant="body2">
                        Reddit
                    </Typography>
                </Stack>
            );
        case "PINTEREST":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaPinterest size="18" />
                    <Typography variant="body2">
                        Pinterest
                    </Typography>
                </Stack>
            );
        case "YOUTUBE":
            return (
                <Stack direction={"row"} alignItems="center" spacing={1}>
                    <FaYoutubeSquare size="18" />
                    <Typography variant="body2">
                        YouTube
                    </Typography>
                </Stack>
            );
        default:
            return (
                <Chip
                    color="error"
                    label="UNKNOWN"
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
    }
};
