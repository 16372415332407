import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { LoginSocialGoogle } from 'reactjs-social-login';
import instance from '../../service/AxiosInterceptor';
import GoogleAuthModal from './GoogleAuthModal';
import { useNavigate } from 'react-router-dom';

function GoogleLoginButton(props: any) {

    const [profile, setProfile] = useState<any>();
    const navigate = useNavigate();

    return (
        (props.status !== "CONNECTED") ?

            <LoginSocialGoogle
                client_id={process.env.REACT_APP_ADB_GOOGLE_CLIENT_ID ?? ''}
                // onLoginStart={onLoginStart}
                redirect_uri={process.env.REACT_APP_ADB_GOOGLE_REDIRECT_URL}
                // scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={(response: any) => {
                    console.log(response)
                    setProfile(response.data);

                    instance
                        .post(
                            process.env.REACT_APP_ADB_BASE_API_URL + "/google/persist-access-token",
                            {
                                "code": response.data.code,
                                "scope": response.data.scope,
                            }
                        ).then((res: any) => {
                            // toast.success("Google account connected!");
                            // props.fetchIntegrations();
                            window.location.href = res.data.url;
                        })
                }}
                onReject={err => {
                    console.error(err);
                    toast.error(JSON.stringify(err))
                }}
            >
                <AddCircleOutlineIcon className='add-icon' />
            </LoginSocialGoogle>
            :
            <RemoveCircleOutlineIcon className='remove-icon' onClick={() => {
                setProfile(null)
                instance
                    .delete("/integrations?uuid=" + props.integrationUuid)
                    .then((response) => {
                        props.fetchIntegrations();
                        toast.success("Google account disconnected!");
                    })
                    .finally(() => {
                    });
            }} />
    )
}

export default GoogleLoginButton