import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import instance from '../../service/AxiosInterceptor';
import BackDrop from '../../util/BackDrop';
import { ChannelEnum } from '../../enum/ChannelEnum';
import { FacebookAd } from './Facebook/FacebookAd';
import { GoogleDisplayAd } from './GoogleDisplay/GoogleDisplayAd';
import { GoogleSearchAd } from './GoogleSearch/GoogleSearchAd';
import { InstagramAd } from './Instagram/InstagramAd';
import { TikTokAd } from './TikTok/TikTokAd';
import { LinkedInAd } from './LinkedIn/LinkedInAd';
import { EtsyAd } from './Etsy/EtsyAd';
import { AmazonAd } from './Amazon/AmazonAd';
import { WalmartAd } from './Walmart/WalmartAd';
import { RedditAd } from './Reddit/RedditAd';
import { InstacartAd } from './Instacart/InstacartAd';
import { YoutubeAd } from './Youtube/YoutubeAd';
import { PinterestAd } from './Pinterest/PinterestAd';
import { TargetAd } from './Target/TargetAd';

export const Ad = (props: any) => {

    const [campaign, setCampaign] = React.useState<any>(null);
    const [searchParams] = useSearchParams();
    const [backdrop, setBackdrop] = React.useState(true);

    const fetchCampaign = () => {
        instance.get("/campaign?campaign_uuid=" + searchParams.get("campaign_uuid"))
            .then((res) => {
                setCampaign(res.data);
            }).finally(() => {
                setBackdrop(false)
            })
    }

    useEffect(() => {
        fetchCampaign()
    }, [])

    const renderAd = () => {
        switch (campaign?.channel) {
            case ChannelEnum.GOOGLE_SEARCH:
                return <GoogleSearchAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />
            case ChannelEnum.GOOGLE_DISPLAY:
                return <GoogleDisplayAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.FACEBOOK:
                return <FacebookAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.INSTAGRAM:
                return <InstagramAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.TIKTOK:
                return <TikTokAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.LINKEDIN:
                return <LinkedInAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.ETSY:
                return <EtsyAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.AMAZON:
                return <AmazonAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.WALMART:
                return <WalmartAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.YOUTUBE:
                return <YoutubeAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.INSTACART:
                return <InstacartAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.REDDIT:
                return <RedditAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.PINTEREST:
                return <PinterestAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.TARGET:
                return <TargetAd
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            default:
                return <>
                    Error! component type not found!
                </>
        }
    }

    return (
        <>
            {
                backdrop
                    ? <BackDrop open={true} />
                    : renderAd()
            }
        </>
    );

}

