
export enum ChannelEnum {
    GOOGLE_DISPLAY = "GOOGLE_DISPLAY",
    GOOGLE_SEARCH = "GOOGLE_SEARCH",
    LINKEDIN = "LINKEDIN",
    INSTAGRAM = "INSTAGRAM",
    FACEBOOK = "FACEBOOK",
    TIKTOK = "TIKTOK",
    ETSY = "ETSY",
    AMAZON = "AMAZON",
    WALMART = "WALMART",
    SNAPCHAT = "SNAPCHAT",
    INSTACART = "INSTACART",
    TARGET = "TARGET",
    REDDIT = "REDDIT",
    PINTEREST = "PINTEREST",
    YOUTUBE = "YOUTUBE",
}
