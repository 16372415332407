import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Box, Button, CircularProgress, Divider, Grid, ListItem, ListItemButton, ListItemText, TextField, Toolbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from "react";
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import * as yup from "yup";
import instance from '../../../service/AxiosInterceptor';
import BackDrop from '../../../util/BackDrop';
import { toast } from 'react-toastify';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function CountryStateCityModal(props: any) {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        resetForm()
        setShowNoResultFound(false)
    };

    const [initialValues, setInitialValues] = useState({
        data: "",
        metadata: "50",
    });

    const validationSchema = yup.object({
        data: yup.string().required(),
        metadata: yup.string().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {
            props.handleSave(values)
            handleClose()
        },
    });

    const { errors, touched, values, handleSubmit, handleChange, handleBlur, resetForm } = formik;

    const [geoSearchResult, setGeoSearchResult] = useState<any>([]);
    const [showNoResultFound, setShowNoResultFound] = useState<any>(false);
    const [debouncedNeedleValue] = useDebounce(values.data, 250);

    React.useEffect(() => {
        if (debouncedNeedleValue.length > 0) {

            setLoading(true)
            setGeoSearchResult([]);

            instance
                .get("/geo/query?needle=" + debouncedNeedleValue)
                .then((res) => {
                    setGeoSearchResult(res.data);
                    setShowNoResultFound(res.data.length === 0)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }, [debouncedNeedleValue])

    return (
        <>
            <Button
                size='small'
                onClick={handleClickOpen}
                variant="outlined"
                startIcon={
                    <AddCircleOutlineIcon />
                }
            >
                <Typography fontWeight="bold" fontSize={13}>
                    Add Country, State, or City
                </Typography>
            </Button>
            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Country, State, or City Details
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <FormikProvider value={formik} >
                        <Form noValidate id="address-radius-form" onSubmit={handleSubmit} >
                            <Grid container>
                                <Grid item xs={12} m={5}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={values.data}
                                        id="data"
                                        label="Country, State, or City"
                                        placeholder="United States"
                                        InputProps={{}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.data && Boolean(errors.data)}
                                        helperText={touched.data && errors.data}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} mx={5}>
                                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {
                                            loading &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CircularProgress />
                                            </Box>
                                        }
                                        {
                                            (!loading && showNoResultFound) &&
                                            < Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography>
                                                    No result found!
                                                </Typography>
                                            </Box>
                                        }
                                        <nav aria-label="secondary mailbox folders">
                                            {geoSearchResult?.map((geo: any, index: any) => (
                                                <>
                                                    <ListItem
                                                        disablePadding
                                                        secondaryAction={
                                                            <AddCircleOutlineIcon
                                                                color='primary'
                                                                onClick={() => {
                                                                    props.handleSave({
                                                                        data: geo.name,
                                                                        metadata: geo.target_type
                                                                    })
                                                                    toast.success("Item added!")
                                                                    setGeoSearchResult([])
                                                                    values.data = ""
                                                                }} />
                                                        }
                                                        key={geo + index}
                                                    >
                                                        <ListItemButton >
                                                            <ListItemText primary={geo.name} />
                                                        </ListItemButton>
                                                    </ListItem >
                                                    <Divider variant="middle" />
                                                </>
                                            ))}
                                        </nav>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                        variant='contained'
                        size='small'
                        sx={{
                            m: 1.5
                        }}
                    >close</Button>
                </DialogActions>
            </BootstrapDialog >
        </>
    );
}
