import { Chip } from "@mui/material";

export const transformCampaignStatus = (value: any) => {
    switch (value) {
        case "LIVE":
            return (
                <Chip
                    color="success"
                    label={value}
                    variant="outlined"
                    size="small"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "PAUSED":
            return (
                <Chip
                    color="default"
                    label={value}
                    variant="outlined"
                    size="small"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "DRAFT":
            return (
                <Chip
                    color="warning"
                    label={value}
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "PROCESSING":
            return (
                <Chip
                    color="primary"
                    label={value}
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "TERMINATED":
        case "PAYMENT_PENDING":
            return (
                <Chip
                    color="error"
                    label={value}
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        default:
            return (
                <Chip
                    color="error"
                    label="UNKNOWN"
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
    }
};