export default function SignUpCompleted() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Sign Up Successful!</h1>
      <p>Thank you for signing up. We’re excited to have you on board!</p>
      <p>
        Please check your email for further instructions on verifying your
        account and getting started.
      </p>
      <button
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#0070f3',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
        onClick={() => window.location.href = '/login'} // Redirect to the login page
      >
        Go to Login
      </button>
    </div>
  );
}