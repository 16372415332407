import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Divider, Grid, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { navy, silver } from "../../../util/Color";
import EmptyTable from "../../../util/EmptyTable";
import AddressAndRadiusModal from "./AddressRadiusModal";
import _ from "lodash";
import { red } from '@mui/material/colors';

interface Column {
    id: "address" | "radius" | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: "address", label: "Address", minWidth: 100 },
    {
        id: "radius",
        label: "Radius",
        minWidth: 100,
    },
    { id: "action", label: "Action", minWidth: 100 },
];

export interface AddressRadiusData {
    address: string;
    radius: string;
}

export default function AddressRadiusTable(props: any) {

    const [locations, setLocations] = React.useState<any>([]);

    const theme = useTheme()

    React.useEffect(() => {
        if (props.locations?.length > 0) {
            setLocations(() => [...locations, ...props.locations]);
        }
    }, []);

    return (
        <>
            {(
                <Grid container p={1}>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <Divider />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead  >
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            colSpan={6}
                                            size="small">
                                            <AddressAndRadiusModal
                                                handleSave={(input: any) => {
                                                    props.handleSave(input)
                                                    setLocations(() => [...locations, input]);
                                                }} />
                                        </TableCell>
                                    </TableRow>

                                    {locations.length > 0 &&
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    size="small"
                                                    sortDirection={false}
                                                    key={column.id}
                                                    align={column.label === 'Action' ? 'right' : column.align}
                                                    sx={{
                                                        color: navy,
                                                        minWidth: column.minWidth,
                                                        ...theme.typography.body2,
                                                        fontWeight: 'bold',
                                                        backgroundColor: silver,
                                                        display: {
                                                            xs: column.label === 'Role' || column.label === 'Status' || column.label === 'Created At' ? 'none' : 'table-cell',
                                                            md: 'table-cell',
                                                        },
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    {locations.length == 0 ? (
                                        <EmptyTable message="Start adding address and radius range to see details here." />
                                    ) : (
                                        locations.map((location: any, index: any) => (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={location.address + index}
                                            >
                                                <TableCell
                                                    sx={{ p: { xs: '6px', sm: '6px 16px' } }}
                                                    className="text-overflow-table"
                                                    size="small"
                                                >
                                                    <Typography
                                                        variant='subtitle2'
                                                    >
                                                        {location?.data}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    sx={{ p: { xs: '6px', sm: '6px 16px' } }}
                                                    className="text-overflow-table"
                                                    size="small"
                                                >
                                                    <Typography
                                                        variant='subtitle2'
                                                    >
                                                        {location?.metadata}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    size="small"
                                                    align="right"
                                                    sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}
                                                >
                                                    <DeleteOutlineIcon
                                                        className="delete-icon"
                                                        onClick={() => {
                                                            setLocations((locations: any) => locations.filter((_: any, i: any) => i !== index));
                                                            props.handleRemove(index)
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            )}
        </>
    );
}
