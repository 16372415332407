
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Chip, Divider, Grid, TableSortLabel, Typography, useMediaQuery, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { AuthContext } from "../../provider/AuthProvider";
import instance from "../../service/AxiosInterceptor";
import { transformPaymentStatusValue } from "../../transformer/TransformPaymentStatus";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import EmptyTable from "../../util/EmptyTable";
import { centToUsd } from "../../util/Util";
import SearchPayment from "./SearchPayment";
import ViewPaymentModal from "./ViewPaymentModal";

interface Column {
    id: "total"
    | "budget"
    | "fee"
    | "bannerFee"
    | "status"
    | "campaign"
    | "createdAt"
    | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    {
        id: "total",
        label: "Total Amount",
        minWidth: 100,
    },
    { id: "budget", label: "Ad Budget", minWidth: 100 },
    { id: "fee", label: "Service Fee", minWidth: 100 },
    { id: "bannerFee", label: "Banner Fee", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "campaign", label: "Campaign Name", minWidth: 100 },
    { id: "createdAt", label: "Created At", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
];

export interface PaymentData {
    paymentUuid: any;
    adBudget: any;
    serviceFee: any;
    campaignName: any;
    bannerFee: any;
    total: any;
    status: any;
    stripeId: any;
    stripePaymentId: any;
    customer: any;
    createdAt: any;
}

export default function PaymentTable() {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

    const { counter, setCounterValue }: any = React.useContext(AuthContext);

    const [payments, setPayments] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState("createdAt");

    const [search, setSearch] = React.useState("");
    const [searchBy, setSearchBy] = React.useState("name");

    const [backdrop, setBackdrop] = React.useState(true);

    const [loggedInUserRole, setLoggedInUserRole] = React.useState<any>(null);

    const setSearchCriteria = (search: any, searchBy: any) => {
        setSearch(search);
        setSearchBy(searchBy);
    };

    const fetchPayments = React.useCallback(
        () => {
            instance
                .get("/payments", {
                    params: {
                        page: page,
                        size: rowsPerPage,
                        order: order,
                        sort: orderBy,
                        [searchBy]: search,
                    },
                })
                .then((response: any) => response.data)
                .then((data: any) => {

                    const newPage = page;
                    setPage(newPage);

                    setPayments(data.data.content);
                    setCounterValue(data.metadata.counter)
                    setTotalElements(data.data.totalElements);
                })
                .finally(() => {
                    setBackdrop(false);
                });
        }, [order, orderBy, page, rowsPerPage, search, searchBy]
    )

    React.useEffect(() => {

        fetchPayments();
        setLoggedInUserRole(localStorage.getItem("role"))

    }, [loggedInUserRole, fetchPayments]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            {backdrop ? (
                <BackDrop open={backdrop} />
            ) : (
                <Grid container py={10}>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    color='white'
                                    fontWeight="bold"
                                    sx={{
                                        flex: "1 1 100%",
                                        px: 2,
                                        py: 0.75,
                                        bgcolor: theme.palette.primary.main,
                                    }}
                                    component="div"
                                    id="tableTitle"
                                >
                                    Payments
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mx={1} my={0}>
                                {counter > 0 && (
                                    <Chip
                                        color="error"
                                        label={`You have ${counter} due payment${counter > 1 ? 's' : ''}, please take a moment to review ${counter > 1 ? 'them' : 'it'}.`}
                                        variant="outlined"
                                        icon={<ErrorOutlineIcon />}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} mx={1} my={0}>
                                <Box my={.5} mt={1.5}>
                                    <SearchPayment setSearchCriteria={setSearchCriteria} />
                                </Box>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" colSpan={8} size="small" sx={{ height: 0 }}>
                                            {/* <SearchPayment setSearchCriteria={setSearchCriteria} /> */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <>
                                            {columns.map((column) => (
                                                <TableCell
                                                    size="small"
                                                    sortDirection={false}
                                                    key={column.id}
                                                    align={column.label === 'Action' ? 'right' : column.align}
                                                    sx={{
                                                        color: navy,
                                                        minWidth: column.minWidth,
                                                        fontVariant: "h4",
                                                        fontWeight: "bold",
                                                        backgroundColor: "",
                                                        display: {
                                                            xs: column.label === 'Service Fee'
                                                                || column.label === 'Status' || column.label === 'Banner Fee' || column.label === 'Campaign Name'
                                                                || column.label === 'Created At' ? 'none' : 'table-cell', sm: 'table-cell'
                                                        },
                                                    }}
                                                >
                                                    {column.label}
                                                    {column.id !== "action" && (
                                                        <TableSortLabel
                                                            sx={{
                                                                "& .MuiTableSortLabel-icon": {
                                                                    color: "black !important",
                                                                },
                                                            }}
                                                            active={column.id === orderBy}
                                                            direction={order}
                                                            onClick={(event) => {
                                                                if (column.id === orderBy) {
                                                                    order === "asc"
                                                                        ? setOrder("desc")
                                                                        : setOrder("asc");
                                                                }
                                                                setOrderBy(column.id);
                                                            }}
                                                        ></TableSortLabel>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {payments.length === 0 ? <EmptyTable message="No payment data found." /> :
                                        payments.map((row: PaymentData) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.paymentUuid}
                                                >
                                                    <TableCell sx={{ p: { xs: '6px 16px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="total"  >
                                                        ${centToUsd(row.total)}
                                                    </TableCell>
                                                    <TableCell sx={{ p: { xs: '6px 16px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="budget"  >
                                                        ${centToUsd(row.adBudget)}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px 16px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="fee"  >
                                                        ${centToUsd(row.serviceFee)}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px 16px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="bannerFee"  >
                                                        ${centToUsd(row.bannerFee)}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px 16px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="status">
                                                        {transformPaymentStatusValue(row.status)}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px 16px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="campaign">
                                                        {row.campaignName}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px 16px', sm: '6px 16px' } }} size="small" key="createdAt">
                                                        <Typography variant="subtitle2">
                                                            {moment(row.createdAt).format(
                                                                "YYYY-MM-DD z"
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell size="small" align="right" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                                                        <ViewPaymentModal payment={row} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper >
                </Grid >
            )
            }
        </>
    );
}
