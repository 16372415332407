import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { AppBar, Toolbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { LoadingButton } from "@mui/lab";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import instance from "../../service/AxiosInterceptor";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function DeleteCampaignModal(props: any) {

    const [open, setOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleDelete(): void {
        setDeleting(true)
        instance
            .delete("/campaign?campaign_uuid=" + props.campaignUuid)
            .then(() => {
                props.fetchCampaigns();
            })
            .finally(() => {
                setOpen(false);
                setDeleting(false)
            });
    }
    return (
        <>
            <DeleteOutlinedIcon
                onClick={handleClickOpen}
                className="delete-icon"
            />

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Confirm
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <Typography sx={{ p: 3 }}>
                        Please confirm to delete the campaign.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={deleting}
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={handleDelete}
                        sx={{ m: 1.5 }}
                    >
                        delete
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}
