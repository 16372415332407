import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

import instance from "../../service/AxiosInterceptor";
import "./Stripe.css";
import SetupForm from "./SetupForm";
import { StepperContext } from "../../provider/StepperProvider";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_ADB_STRIPE_PK ?? '');

export default function AddPaymentMethodStripe(props: any) {

  const { campaignUuid }: any = React.useContext(StepperContext);

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    instance
      .post("/create-setup-intent")
      .then((res) => {
        setClientSecret(res.data.clientSecret)
      })
  }, []);

  const appearance = {
    // theme: 'flat'
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="container__stripe">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <SetupForm campaignUuid={campaignUuid} />
        </Elements>
      )}
    </div>
  );
}