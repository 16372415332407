import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Card, CardContent, CardHeader, Chip, Divider, Grid, Slider, Stack, Typography, useTheme } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";
import _ from "lodash";
import moment from 'moment';
import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import instance from "../../service/AxiosInterceptor";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import { calculatePercentage, centToUsd, convertCentsToDollars, usdToCent } from "../../util/Util";
import AddPaymentMethodModal from "../Payment/AddPaymentMethodModal";
import { LoadingButton } from '@mui/lab';

export const Payment = (props: any) => {

    const theme = useTheme();

    const [backdrop, setBackdrop] = React.useState(true);
    const [placingOrder, setPlacingOrder] = React.useState(false);
    const [prices, setPrices] = React.useState<any>([]);
    const [campaign, setCampaign] = React.useState<any>(null);
    const [paymentMethod, setPaymentMethod] = React.useState<any>(null);
    const [searchParams] = useSearchParams();

    const fetchCampaign = async () => {
        const res = await instance.get("/campaign?campaign_uuid=" +
            searchParams.get("campaign_uuid"))
        setCampaign(res.data);
    }

    const fetchPaymentMethod = async () => {
        const res = await instance.get(`payment-methods`);
        setPaymentMethod(res.data);
    }

    const fetchPrices = async () => {
        const res = await instance.get(`/prices`);
        setPrices(res.data);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchCampaign(), fetchPrices(), fetchPaymentMethod()]);
            } finally {
                setBackdrop(false);
            }
        };

        fetchData();
    }, []);

    const currentDate = dayjs();
    let nextBusinessDay = currentDate;
    let businessDaysToAdd = 3;
    let serviceFeePercentage = 20;

    while (businessDaysToAdd > 0) {
        nextBusinessDay = nextBusinessDay.add(1, 'day');
        if (nextBusinessDay.day() !== 0 && nextBusinessDay.day() !== 6) {
            businessDaysToAdd--;
        }
    }

    const [launchDate, setLaunchDate] = React.useState<any>(nextBusinessDay);
    const [adBudget, setAdBudget] = React.useState<number>(500 * 100);
    const handleChange = (_: Event, value: number | number[]) => {
        setAdBudget(usdToCent(value as number));
    };

    function handleLaunchDateChange(input: any): void {
        setLaunchDate(input);
    }
    const handlePlaceOrder = () => {
        setPlacingOrder(true)
        instance.post("/payments", {
            ad_budget: adBudget,
            banner_order: campaign.ads[0].banner_order,
            last4: paymentMethod.last4,
            card_brand: paymentMethod.brand,
            campaign_uuid: campaign.campaign_uuid,
            launch_date: launchDate,
            ad_uuid: campaign.ads[0].ad_uuid
        }).then(
            () => {
                props.handleNext()
            }
        ).finally(() => {
            setPlacingOrder(false)
        })
    }

    return (
        <>
            {
                backdrop ? <BackDrop open={true} /> :
                    <Grid container
                        mt={2}
                        justifyContent={"center"}
                        alignItems="center"
                    >
                        <Card sx={{ width: { xs: '100%' } }}>
                            <CardHeader
                                title="Payment"
                                titleTypographyProps={{
                                    textAlign: "left",
                                    fontSize: "1.1rem",
                                    color: "white",
                                }}
                                subheader=""
                                subheaderTypographyProps={{ textAlign: "left" }}
                                style={{ backgroundColor: theme.palette.primary.main, height: "2.2rem" }}
                            />
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sm={4} m={1}>
                                        <Typography fontWeight={"bold"} color={navy}>
                                            Launch Date
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Your campaign will be live in 5 business days.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={7} border={""}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer components={['DatePicker']} sx={{
                                                minHeight: "50px"
                                            }}>
                                                <DatePicker
                                                    defaultValue={nextBusinessDay}
                                                    minDate={nextBusinessDay}
                                                    onChange={handleLaunchDateChange}
                                                    slotProps={{
                                                        textField: {
                                                            variant: "standard"
                                                        },
                                                        openPickerButton: {
                                                            color: 'primary',
                                                        },
                                                        inputAdornment: {
                                                            position: 'start',
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Divider style={{
                                    margin: 10
                                }} />
                                <Grid container>
                                    <Grid item xs={12} sm={4} m={1}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography fontWeight="bold" color={navy}>
                                                Monthly Ad Budget
                                            </Typography>
                                            {/* <CustomizedTooltips variant="subtitle2" message="Monthly ad budget will be paid in full 
                                            to the publishers to show ads." /> */}
                                        </Stack>
                                        <Typography variant="subtitle2">
                                            Will be paid in full
                                            to the publishers to show ads.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={7}>
                                        <Stack rowGap={0}>
                                            <Box display="flex" alignItems="center" margin={1}>
                                                <Typography fontWeight={""} variant="h5">
                                                    ${centToUsd(adBudget)}
                                                </Typography>
                                            </Box>
                                            {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center"> */}
                                            {/* <Typography
                                                    variant="body2"
                                                    onClick={() => setAdBudget(500)}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    Min: ${_.find(prices, { type: 'min_ad_budget' })?.price}
                                                </Typography> */}
                                            <Box sx={{ width: 200, ml: 2 }}>
                                                <Slider
                                                    size="medium"
                                                    marks
                                                    step={100}
                                                    value={
                                                        centToUsd(adBudget)
                                                    }
                                                    min={
                                                        centToUsd(_.find(prices, { type: 'min_ad_budget' })?.price)
                                                    }
                                                    max={
                                                        centToUsd(_.find(prices, { type: 'max_ad_budget' })?.price)
                                                    }
                                                    defaultValue={
                                                        centToUsd(_.find(prices, { type: 'min_ad_budget' })?.price)
                                                    }
                                                    aria-label="Default"
                                                    valueLabelDisplay="off"
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                            {/* <Typography
                                                    variant="body2"
                                                    onClick={() => setAdBudget(5000)}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    Max: ${_.find(prices, { type: 'max_ad_budget' })?.price}
                                                </Typography> */}
                                            {/* </Stack> */}
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Divider style={{
                                    margin: 10
                                }} />
                                <Grid container mt={1}>
                                    <Grid item xs={12} sm={6} md={4} m={1}>
                                        <Typography fontWeight={"bold"} color={navy}>
                                            Service Fee
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {
                                                _.find(prices, { type: 'service_fee_ad_budget_percentage' })?.price
                                            }% of ad budget
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Will be used to set up and optimize the campaigns.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={7} lg={7} m={1}>
                                        <Box display="flex" alignItems="center">
                                            <Typography fontWeight={""} variant="h5" mr={1}>
                                                ${
                                                    centToUsd(calculatePercentage(
                                                        _.find(prices, { type: 'service_fee_ad_budget_percentage' })?.price, adBudget)
                                                    )
                                                }
                                            </Typography>
                                            <Typography>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {
                                    campaign.ads[0]?.banner_order &&
                                    <>
                                        <Divider style={{
                                            margin: 10
                                        }} />
                                        <Grid container mt={1}>
                                            <Grid item xs={12} sm={6} md={4} m={1}>
                                                <Typography fontWeight={"bold"} color={navy}>
                                                    One-Time Banner Design Fee
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    With 3 free revisions
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={7} lg={7} m={1}>
                                                <Box display="flex" alignItems="center">
                                                    <Typography variant="h5" mr={1}>${
                                                        centToUsd(
                                                            _.find(prices, { type: 'google_display_banner_design' })?.price
                                                        )
                                                    }</Typography>
                                                    <Typography>
                                                    </Typography>
                                                </Box>


                                            </Grid>
                                        </Grid>
                                    </>

                                }
                                <Divider style={{
                                    margin: 10
                                }} />

                                <Grid container>
                                    <Grid item xs={12} sm={6} md={4} m={1}>
                                        <Typography fontWeight={"bold"} color={navy}>
                                            Payment Method
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} mt={1}>
                                        {
                                            !paymentMethod?.id ?
                                                <AddPaymentMethodModal />
                                                :
                                                <Chip size="small"
                                                    label={
                                                        <Typography fontWeight={""}>
                                                            {_.toUpper(paymentMethod?.brand)} .... {paymentMethod?.last4}
                                                        </Typography>
                                                    } variant="outlined" />
                                        }
                                    </Grid>
                                </Grid>
                                <Divider style={{ margin: 10 }} />

                                <Grid container>
                                    <Grid item xs={12} sm={6} md={4} m={1}>
                                        <Typography fontWeight={"bold"} variant="h5" color={navy}>
                                            Total
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <>
                                                <Typography variant="subtitle2" fontWeight="">
                                                    ${
                                                        centToUsd(
                                                            adBudget +
                                                            calculatePercentage(serviceFeePercentage, adBudget))
                                                    }{' '}
                                                    Due on the {moment().format('Do')} of Every Month
                                                </Typography>
                                                {campaign.ads[0]?.banner_order && (
                                                    <Typography variant="subtitle2" fontWeight="">
                                                        ${
                                                            centToUsd(
                                                                _.find(prices, { type: 'google_display_banner_design' })?.price
                                                            )
                                                        }{' '}
                                                        One-Time Fee
                                                    </Typography>
                                                )}
                                            </>
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            You can pause or cancel the campaign at any time.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={7}>
                                        <Stack>
                                            <Box display="flex" alignItems="center">
                                                <Typography
                                                    color={navy}
                                                    variant="h5"
                                                    m={1}
                                                    fontWeight={"bold"}>
                                                    ${
                                                        centToUsd(
                                                            adBudget +
                                                            calculatePercentage(serviceFeePercentage, adBudget) +
                                                            (
                                                                campaign.ads[0]?.banner_order &&
                                                                _.find(prices, { type: 'google_display_banner_design' })?.price
                                                            )
                                                        )
                                                    }
                                                </Typography>
                                            </Box>

                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Divider style={{
                                    margin: 10
                                }} />

                                <Grid container mt={2}>
                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button
                                            autoFocus
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                props.handleBack()
                                            }}
                                        >
                                            <Typography fontWeight={"bold"} fontSize={13}>
                                                back
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <LoadingButton
                                            loading={placingOrder}
                                            color={"primary"}
                                            autoFocus
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                handlePlaceOrder()
                                            }}
                                            endIcon={<CheckCircleOutlineIcon fontSize="large" />}
                                            disabled={!paymentMethod?.id}
                                        >
                                            <Typography
                                                fontWeight={"bold"}
                                                fontSize={13}>
                                                place order
                                            </Typography>
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid >
            }
        </>
    );
}

