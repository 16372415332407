import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const NotFound = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/'); // Replace '/' with the path of your home page
    };

    return (
        <Box width={'100%'}
            m={10}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column" >
            <Grid item>
                <Typography color={"red"}>
                    404
                </Typography>
            </Grid>
            <Grid item m={5}>
                <Button
                    onClick={handleClick}
                    variant="contained"
                    size="small"
                    sx={{ width: { xs: '100%', sm: 150, fontWeight: "bold" } }}
                >
                    Home
                </Button>
            </Grid>
        </Box>
    );
}

export default NotFound