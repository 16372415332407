import { EditOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Grid, TableSortLabel, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { CampaignStatusEnum } from "../../enum/CampaignStatusEnum";
import instance from "../../service/AxiosInterceptor";
import { transformCampaignStatus } from '../../transformer/TransformCampaignStatus';
import { transformChannelValue } from '../../transformer/TransformChannelStatus';
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import EmptyTable from "../../util/EmptyTable";
import DeleteCampaignModal from './DeleteCampaignModal';
import PauseCampaignModal from "./PauseCampaignModal";
import ResumeCampaignModal from "./ResumeCampaignModal";
import SearchCampaign from './SearchCampaign';
import ViewCampaignModal from './ViewCampaignModal';

interface Column {
    id: "name" | "channel" | "status" | "createdAt" | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    {
        id: "name",
        label: "Name",
        minWidth: 100,
    },
    { id: "channel", label: "Channel", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "createdAt", label: "Created At", minWidth: 100 },
    { id: "action", label: "Actions", minWidth: 100 },
];

export interface CampaignData {
    campaign_uuid: string;
    name: string;
    channel: string;
    status: string;
    created_at: string;
}

export default function CampaignTable() {

    const navigate = useNavigate();
    const theme = useTheme();

    const [campaigns, setCampaigns] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    type Order = "asc" | "desc";
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState("createdAt");

    const [search, setSearch] = React.useState("");
    const [searchBy, setSearchBy] = React.useState("name");

    const [backdrop, setBackdrop] = React.useState(true);

    const [loggedInUserRole, setLoggedInUserRole] = React.useState<any>(null);

    const renderActions = (row: any) => {
        switch (row.status) {
            case CampaignStatusEnum.LIVE:
                return (
                    <PauseCampaignModal
                        campaignUuid={row.campaign_uuid}
                        fetchCampaigns={fetchCampaigns}
                    />
                );
            case CampaignStatusEnum.PAUSED:
                return (
                    <ResumeCampaignModal
                        campaignUuid={row.campaign_uuid}
                        fetchCampaigns={fetchCampaigns}
                    />
                );
            case CampaignStatusEnum.PROCESSING:
                return (
                    <ViewCampaignModal campaign={row} />
                );
            case CampaignStatusEnum.DRAFT:
                return (
                    <>
                        <ViewCampaignModal campaign={row} />
                        <EditOutlined
                            className="edit-icon"
                            onClick={() => {
                                navigate(`/campaign-stepper?campaign_uuid=${row.campaign_uuid}`, {
                                    replace: true
                                });
                            }}
                        />
                        <DeleteCampaignModal
                            campaignUuid={row.campaign_uuid}
                            fetchCampaigns={fetchCampaigns}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    const fetchCampaigns = React.useCallback(
        () => {
            instance
                .get("/campaigns", {
                    params: {
                        page: page,
                        size: rowsPerPage,
                        order: order,
                        sort: orderBy,
                        [searchBy]: search,
                    },
                })
                .then((response: any) => response.data)
                .then((data: any) => {

                    const newPage = page;
                    setPage(newPage);

                    setCampaigns(data.content);
                    setTotalElements(data.totalElements);
                })
                .finally(() => {
                    setBackdrop(false);
                });
        }, [order, orderBy, page, rowsPerPage, search, searchBy]
    )

    React.useEffect(() => {

        fetchCampaigns();
        setLoggedInUserRole(localStorage.getItem("role"))

    }, [fetchCampaigns]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const setSearchCriteria = (search: any, searchBy: any) => {
        setSearch(search);
        setSearchBy(searchBy);
    };

    return (
        <>
            {backdrop ? (
                <BackDrop open={backdrop} />
            ) : (
                <Grid container py={10}>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <Typography
                            fontWeight={"bold"}
                            sx={{ flex: "1 1 100%" }}
                            id="tableTitle"
                            component="div"
                            px={2}
                            py={0.75}
                            border={"solid 0.5px silver"}
                            bgcolor={theme.palette.primary.main}
                            color={'white'}
                        >
                            Campaigns
                        </Typography>
                        <Divider />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow sx={{ display: 'table-row' }}>
                                        <TableCell align="left" colSpan={6} size="small">
                                            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'center', sm: 'space-between' }} alignItems={'center'}>
                                                <SearchCampaign setSearchCriteria={setSearchCriteria} />
                                                <Box width={{ xs: '100%', sm: 'auto' }}>
                                                    <Button variant="contained" size="small"
                                                        onClick={() => {
                                                            navigate("/campaign-stepper?campaign_uuid=" + uuidv4(), {
                                                                replace: true
                                                            });
                                                        }}
                                                        fullWidth={true}
                                                    >
                                                        <Typography
                                                            fontWeight={"bold"}
                                                            fontSize={13}>Create Campaign</Typography>
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                size="small"
                                                sortDirection={false}
                                                key={column.id}
                                                align={column.label === 'Actions' ? 'right' : column.align}
                                                sx={{
                                                    color: navy,
                                                    minWidth: column.minWidth,
                                                    fontWeight: "bold",
                                                    fontSize: "",
                                                    backgroundColor: "",
                                                    display: {
                                                        xs: column.label === 'Role'
                                                            || column.label === 'Status'
                                                            || column.label === 'Created At' ? 'none' : 'table-cell', md: 'table-cell'
                                                    },
                                                }}
                                            >
                                                {column.label}
                                                {column.id !== "action" && (
                                                    <TableSortLabel
                                                        sx={{
                                                            "& .MuiTableSortLabel-icon": {
                                                                color: "black !important",
                                                            },
                                                        }}
                                                        active={column.id === orderBy}
                                                        direction={order}
                                                        onClick={(event) => {
                                                            if (column.id === orderBy) {
                                                                order === "asc"
                                                                    ? setOrder("desc")
                                                                    : setOrder("asc");
                                                            }
                                                            setOrderBy(column.id);
                                                        }}
                                                    ></TableSortLabel>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaigns?.length === 0 ? <EmptyTable message="
                                        No campaign found. Tap Create Campaign to get started.
                                    "/> :
                                        campaigns?.map((row: CampaignData) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.campaign_uuid}
                                                >
                                                    <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="name"  >
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="channel">
                                                        {transformChannelValue(row.channel)}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="status">
                                                        {transformCampaignStatus(row.status)}
                                                    </TableCell>
                                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="createdAt">
                                                        <Typography variant="subtitle2">
                                                            {moment(row.created_at).format(
                                                                "YYYY-MM-DD HH:mm z"
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell size="small" align="right" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                                                        {renderActions(row)}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid >
            )
            }
        </>
    );
}
