import axios from "axios";
import { createContext, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const AuthContext = createContext({});

export const AuthProvider = ({ children }: any) => {

  const [counter, setCounter] = useState(localStorage.getItem("counter"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [onboardingStatus, setOnboardingStatus] = useState(localStorage.getItem("onboarding_status"));
  const [accountUuid, setAccountUuid] = useState(localStorage.getItem("account_uuid"));

  const setCounterValue = (value: string) => {
    localStorage.setItem("counter", value)
    setCounter(value);
  };

  const navigate = useNavigate();

  const login = useCallback(async ({ email, password }: any) => {

    await axios
      .post(process.env.REACT_APP_ADB_BASE_API_URL + "/signin", {
        email: email,
        password: password,
      })
      .then((res) => {

        setCounter(res.data.counter)
        setToken(res.data.token)
        setRole(res.data?.role)
        setOnboardingStatus(res.data.onboarding_status)
        setAccountUuid(res.data.account_uuid)

        localStorage.setItem("counter", res.data.counter)
        localStorage.setItem("token", res.data.token)
        localStorage.setItem("role", res.data.role)
        localStorage.setItem("account_uuid", res.data.account_uuid)
        localStorage.setItem("onboarding_status", res.data.onboarding_status)

        navigate("/dashboard");

      })
      .catch((err: any) => {
        toast.error(err.response?.data.message || "Error occurred!")
      });
  }, [navigate])

  const logout = useCallback(() => {

    localStorage.removeItem("token")
    localStorage.removeItem("role")
    localStorage.removeItem("account_uuid")
    localStorage.removeItem("onboarding_status")
    localStorage.removeItem("link_token")
    localStorage.removeItem("counter")

    setToken(null)
    navigate("/signin");
  }, [navigate])

  const value =
    useMemo(
      () => ({
        login,
        logout,
        token,
        role,
        onboardingStatus,
        accountUuid,
        counter,
        setCounterValue,
      }),
      [token, accountUuid, onboardingStatus, role, counter, logout, login, setCounterValue]
    );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

