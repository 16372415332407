import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackDrop from '../../util/BackDrop';
import { Ad } from './Ad';
import Campaign from './Campaign';
import { Payment } from './Payment';
import { Preview } from './Preview';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const steps = ['Campaign', 'Ad', 'Preview', 'Payment'];

export default function CampaignStepper(props: any) {

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [backdrop] = React.useState(false);

  const isStepFailed = (step: number) => {
    return false
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  function renderStepComponent(activeStep: any) {
    switch (activeStep) {
      case 0:
        return <Campaign
          handleNext={handleNext}
          campaignUuid={searchParams.get("campaign_uuid")}
        />;
      case 1:
        return <Ad
          handleBack={handleBack}
          handleNext={handleNext}
        />;
      case 2:
        return <Preview
          handleBack={handleBack}
          handleNext={handleNext}
        />;
      case 3:
        return <Payment
          handleBack={handleBack}
          handleNext={handleNext}
        />;
      default:
        throw new Error("render-step command not supported!")
    }
  }

  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  React.useEffect(() => {
    if (searchParams.get("step")) {
      setActiveStep(3)
    }
  }, [searchParams])

  return (
    <>
      {
        backdrop ? <BackDrop open={true} /> :
          <Box sx={{ width: '100%' }} my={7.5}>
            <Stepper activeStep={activeStep} orientation={isTablet ? "vertical" : "horizontal"}>
              {steps.map((label, index) => {
                const labelProps: {
                  optional?: React.ReactNode;
                  error?: boolean;
                } = {};
                if (isStepFailed(index)) {
                  labelProps.optional = (
                    <Typography variant="caption" color="error">
                      Alert message
                    </Typography>
                  );
                  labelProps.error = true;
                }

                return (
                  <Step key={label} sx={{
                    fontWeight: "bold",
                    fontSize: "32px"
                  }}>
                    <StepLabel {...labelProps}>
                      <Typography variant='h6'>
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {
              activeStep === steps.length ? (
                <Grid
                  mt={5}
                  container
                  style={{ minWidth: '100%', minHeight: '50h' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} m={{ xs: 0, sm: 2 }}>
                    <Card>
                      <CardContent >
                        <Typography variant="h5" component="h2"
                          align="center" fontWeight={"bold"} m={1}
                        >
                          You are all set!
                        </Typography>
                      </CardContent>
                      <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                          Your order has been submitted.
                          We will begin working on it and keep you posted with updates.
                        </Typography>
                      </CardContent>
                      <CardActions style={{ justifyContent: 'flex-end' }}>
                        <Button
                          size="small"
                          variant='contained'
                          onClick={() => {
                            navigate("/campaigns", { replace: true })
                          }}
                        ><Typography
                          fontSize={13}
                          fontWeight={"bold"}>
                            Go to Campaigns
                          </Typography>
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <React.Fragment>
                  {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {
                      activeStep !== 0 &&
                      <Button
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{ mr: 1 }}
                        size='small'
                        startIcon={<ArrowBackIcon />}>
                        <Typography fontWeight={"bold"}>Back</Typography>
                      </Button>
                    }
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep !== steps.length - 1 &&
                      <Button
                        onClick={handleNext}
                        // disabled={activeStep === 0}
                        sx={{ mr: 1 }}
                        size='small'
                        endIcon={<ArrowForwardIcon />}>
                        <Typography fontWeight={"bold"}>Next</Typography>
                      </Button>}
                  </Box> */}
                  {
                    renderStepComponent(activeStep)
                  }
                </React.Fragment>
              )
            }
          </Box >

      }
    </>
  );
}
