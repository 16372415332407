import { Chip } from "@mui/material";

export const transformValue = (value: any) => {
    switch (value) {
        case "ACTIVE":
            return (
                <Chip
                    color="success"
                    label={value}
                    variant="outlined"
                    size="small"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "INVITED":
            return (
                <Chip
                    color="warning"
                    label={value}
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "SUSPENDED":
            return (
                <Chip
                    color="secondary"
                    label={value}
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        case "TERMINATED":
            return (
                <Chip
                    color="error"
                    label={value}
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
        default:
            return (
                <Chip
                    color="error"
                    label="UNKNOWN"
                    size="small"
                    variant="outlined"
                    style={{ fontSize: 10, letterSpacing: 2, fontWeight: "bold" }}
                    sx={{ height: "20px", paddingTop: "1.5px" }}
                />
            );
    }
};