import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { navy } from "../util/Color";

export const transformCardBrandValue = (value: any) => {
    switch (value) {
        case "VISA":
            return (
                <FaCcVisa size={30} color={navy} />
            );
        case "MASTERCARD":
            return (
                <FaCcMastercard size={30} color={navy} />
            );
        default:
            return value;
    }
};