import { Divider, Grid, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import instance from "../../service/AxiosInterceptor";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import EmptyTable from "../../util/EmptyTable";
import _ from "lodash";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddPaymentMethodModal from "../Payment/AddPaymentMethodModal";
import { transformCardBrandValue } from "../../transformer/TransformCardBrandValue";

interface Column {
    id: "brand" | "last4" | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: "brand", label: "Brand", minWidth: 100 },
    {
        id: "last4",
        label: "Card #",
        minWidth: 100,
    },
    { id: "action", label: "Action", minWidth: 100 },
];

export interface PaymentMethodData {
    payment_method_uuid: string;
    brand: string;
    last4: string;
}

export default function PaymentMethodTable() {

    const theme = useTheme();

    const [paymentMethod, setPaymentMethod] = React.useState<any>(null);
    const [backdrop, setBackdrop] = React.useState(true);

    const fetchPaymentMethod = React.useCallback(
        () => {
            instance
                .get("/payment-methods", {
                })
                .then((res: any) => {
                    setPaymentMethod(res.data);
                })
                .finally(() => {
                    setBackdrop(false);
                });
        }, []
    )

    React.useEffect(() => {
        fetchPaymentMethod();
    }, []);

    const handleDelete = () => {
        instance.delete("/payment-methods").then(() => {
            setPaymentMethod(null)
        })
    }

    return (
        <>
            {backdrop ? (
                <BackDrop open={backdrop} />
            ) : (
                <Grid container>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <Typography
                            fontWeight={"bold"}
                            sx={{ flex: "1 1 100%" }}
                            id="tableTitle"
                            component="div"
                            px={2}
                            py={0.75}
                            border={"solid 0.5px silver"}
                            bgcolor={theme.palette.primary.main}
                            color={"white"}
                        >
                            Payment Method
                        </Typography>
                        <Divider />
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    {
                                        !paymentMethod?.id &&
                                        <TableRow>
                                            <TableCell align="right" colSpan={6} size="small">
                                                <AddPaymentMethodModal />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                size="small"
                                                sortDirection={false}
                                                key={column.id}
                                                align={column.label === 'Action' ? 'right' : column.align}
                                                sx={{
                                                    color: navy,
                                                    minWidth: column.minWidth,
                                                    fontVariant: "h4",
                                                    fontWeight: "bold",
                                                    backgroundColor: "",
                                                    display: {
                                                        xs: column.label === 'Role'
                                                            || column.label === 'Status'
                                                            || column.label === 'Created At' ? 'none' : 'table-cell', md: 'table-cell'
                                                    },
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!paymentMethod?.id ? <EmptyTable message="Start adding payment methods to see details here." /> :
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={paymentMethod?.last4}
                                        >
                                            <TableCell sx={{ p: { xs: '6px', sm: '6px 16px' } }} className="text-overflow-table" size="small" key="brand"  >
                                                {transformCardBrandValue(_.toUpper(paymentMethod.brand))}
                                            </TableCell>
                                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, p: { xs: '6px', sm: '6px 16px' } }} size="small" key="last4">
                                                <Typography variant="body1" style={{ fontSize: '1rem', letterSpacing: '0.1em' }}>
                                                    {'\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 '}{paymentMethod.last4}
                                                </Typography>
                                            </TableCell>
                                            <TableCell size="small" align="right" sx={{ minWidth: '100px', p: { xs: '6px', sm: '6px 16px' } }}>
                                                <DeleteOutlineIcon
                                                    className="delete-icon"
                                                    onClick={
                                                        handleDelete
                                                    } />
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            )}
        </>
    );
}
