import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, Select, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

export default function SearchBusiness(props: any) {

  const [search, setSearch] = useState<any>();
  const [searchBy, setSearchBy] = useState("referral_email");

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: { xs: '100%', md: 400 } }}
    >
      <Select
        variant="standard"
        labelId="select-label"
        id="select"
        size="small"
        onChange={(event: any) => setSearchBy(event.target.value)}
        disableUnderline
        sx={{
          p: 1,
          minWidth: "70px",
          height: "10px",
        }}
        value={searchBy}
      >
        <MenuItem value="referral_email">
          <Typography variant="subtitle2" pt={0.7}>
            Name
          </Typography>
        </MenuItem>
      </Select>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Referrals"
        inputProps={{ "aria-label": "search referrals" }}
        value={search}
        onChange={(event: any) => setSearch(event.target.value)}
      />
      {search?.trim().length > 0 && (
        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          aria-label="clear"
          type="button"
          onClick={() => {
            setSearch("");
            props.setSearchCriteria("", searchBy);
          }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      )}

      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        color="primary"
        sx={{ p: "10px" }}
        aria-label="search"
        type="button"
        onClick={() => {
          props.setSearchCriteria(search, searchBy);
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
