import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppBar, Grid, Stack, Toolbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";
import 'moment-timezone';
import * as React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ViewReferralModal({ referral }: any) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <VisibilityIcon
        className="view-icon"
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              View Referral
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Referral Email: </Typography>
                <Typography variant="subtitle2">{referral?.email}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Referral Status: </Typography>
                <Typography variant="subtitle2">{referral?.status}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">Created At:</Typography>
                <Typography variant="subtitle2">
                  {moment(referral?.created_at).format("YYYY-MM-DD hh:mm A z") +
                    "(" +
                    moment.tz.guess() +
                    " Time)"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
