import { Campaign, Storefront } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import MenuIcon from '@mui/icons-material/Menu';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import SatelliteIcon from '@mui/icons-material/Satellite';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import { Badge, Grid } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from '../../provider/AuthProvider';
import { navy } from '../../util/Color';
import AccountMenu from './AccountMenu';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('md')]: {
    width: `0px`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const getIcon = (text: any, counter: number) => {
  switch (text) {
    case "Dashboard":
      return <SpeedIcon style={{ color: navy }} />;
    case "Users":
      return <GroupIcon style={{ color: navy }} />;
    case "Business":
      return <Storefront style={{ color: navy }} />;
    case "Ad":
      return <QueuePlayNextIcon style={{ color: navy }} />;
    case "Creatives":
      return <SatelliteIcon style={{ color: navy }} />;
    case "Tracking":
      return <InsightsIcon style={{ color: navy }} />;
    case "Settings":
      return <SettingsIcon style={{ color: navy }} />;
    case "Support":
      return <ContactSupportIcon style={{ color: navy }} />;
    case "Campaigns":
      return <Campaign style={{ color: navy }} />;
    case "Referrals":
      return <DeviceHubIcon style={{ color: navy }} />;
    case "Payments":
      return (
        counter > 0 ? (
          <Badge
            badgeContent={counter}
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                width: '18px',
                height: '18px',
                fontSize: '10px',
                fontWeight: "bold"
              }
            }}
          >
            <AttachMoneyIcon style={{ color: navy }} />
          </Badge>
        ) : (
          <AttachMoneyIcon style={{ color: navy }} />
        ));
    default:
      throw new Error("Invalid Icon!")
  }
};

export default function SideMenu() {

  const { token }: any = React.useContext(AuthContext);
  const { counter }: any = React.useContext(AuthContext);

  const theme = useTheme()
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    setOpen(false);
  }, [token, counter]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function getListItemStyle(text: any) {
    if (location.pathname.includes(text.toLowerCase())) {
      return {
        borderRight: `2px solid ${navy}`,
        backgroundColor: "rgba(27,59,101, .1)"
      };
    }
    else {
      return {};
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={isTablet ? false : open} style={{ backgroundColor: navy }}>
        <Grid container alignItems={'center'}>
          <Grid item xs={6} style={{}}>
            <Toolbar>
              {token && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setOpen(!open)}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    display: { xs: 'block', md: open ? 'none' : 'block' },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h6" noWrap component="div" fontWeight={"bold"} letterSpacing={1.5} color={''}>
                  AdBolder.
                </Typography>
              </Link>
            </Toolbar>
          </Grid>

          {token && (
            <Grid item xs={6} padding={1}>
              <Box display="flex" justifyContent="flex-end">
                <AccountMenu />
              </Box>
            </Grid>
          )}
        </Grid>
      </AppBar>
      {token && (
        <Drawer variant={"permanent"} open={open}
          sx={{ position: { xs: 'fixed', md: 'relative' }, zIndex: 999, }}
        >
          <DrawerHeader style={{}}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={{}}>
            {[
              'Dashboard',
              'Campaigns',
              // 'Business',
              // 'Ad',
              // 'Creatives',
              // 'Tracking',
              'Payments',
              'Users',
              'Settings',
              'Referrals',
              // 'Support',
            ].map((text, index) => (
              <ListItem component={Link}
                to={"/" + text.toLowerCase()}
                key={text}
                disablePadding sx={{ display: 'block' }}
                style={
                  getListItemStyle(text)
                }
                onClick={handleDrawerClose}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {getIcon(text, counter)}
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: navy }}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      fontSize: "small",
                    }}
                    primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Drawer>
      )}
      {/* <Box component="main" sx={{ flexGrow: 1, p: { xs: 0, md: 3 } }}>
        <DrawerHeader />
      </Box> */}
    </Box >
  );
}