import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import {
    AppBar,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Toolbar
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


export default function RedditAdWorkSampleModal(props: any) {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Typography variant="subtitle2"
                onClick={handleClickOpen}
                style={{ cursor: "pointer" }}>
                Click <span style={{ color: theme.palette.primary.main }}>here</span> to see our previous designed banners.
            </Typography>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
                maxWidth={"lg"}
                fullWidth={true}
            // fullScreen={true}

            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                            Google Display Ad Banners
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <Grid container alignItems="center"
                        justifyContent="center">
                        {Array.from({ length: 20 }, (_, i) => i + 1).map((index) => (
                            <>
                                <Grid item xs={12} key={index} my={2}  >
                                    <img
                                        style={{ width: "70rem" }}
                                        alt={`banner-${index}`}
                                        src={`https://test-adb-creatives.s3.Redditaws.com/google-display-work-sample-banners/banner-${index}.jpg`} />
                                </Grid>
                                <Divider />
                            </>
                        ))}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleClose}
                        sx={{ m: 1.5 }}
                    >
                        ok
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
}
