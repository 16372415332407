import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ChannelEnum } from '../../enum/ChannelEnum';
import instance from '../../service/AxiosInterceptor';
import BackDrop from '../../util/BackDrop';
import GoogleSearchAdPreview from './GoogleSearch/GoogleSearchAdPreview';
import GoogleDisplayAdPreview from './GoogleDisplay/GoogleDisplayAdPreview';
import FacebookAdPreview from './Facebook/FacebookAdPreview';
import { InstagramAdPreview } from './Instagram/InstagramAdPreview';
import TikTokAdPreview from './TikTok/TikTokAdPreview';
import LinkedInAdPreview from './LinkedIn/LinkedInAdPreview';
import YoutubeAdPreview from './Youtube/YoutubeAdPreview';
import PinterestAdPreview from './Pinterest/PinterestAdPreview';
import RedditAdPreview from './Reddit/RedditAdPreview';
import TargetAdPreview from './Target/TargetAdPreview';
import InstacartAdPreview from './Instacart/InstacartAdPreview';
import SnapchatAdPreview from './Snapchat/SnapchatAdPreview';
import WalmartAdPreview from './Walmart/WalmartAdPreview';
import EtsyAdPreview from './Etsy/EtsyAdPreview';
import AmazonAdPreview from './Amazon/AmazonAdPreview';


export const Preview = (props: any) => {

    const [campaign, setCampaign] = React.useState<any>(null);
    const [searchParams] = useSearchParams();
    const [backdrop, setBackdrop] = React.useState(true);

    const fetchCampaign = () => {
        instance.get("/campaign?campaign_uuid=" + searchParams.get("campaign_uuid"))
            .then((res) => {
                setCampaign(res.data);
            }).finally(() => {
                setBackdrop(false)
            })
    }

    const renderAdPreview = () => {
        switch (campaign?.channel) {
            case ChannelEnum.GOOGLE_SEARCH:
                return <GoogleSearchAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.GOOGLE_DISPLAY:
                return <GoogleDisplayAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.FACEBOOK:
                return <FacebookAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.INSTAGRAM:
                return <InstagramAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.TIKTOK:
                return <TikTokAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.LINKEDIN:
                return <LinkedInAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.AMAZON:
                return <AmazonAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.ETSY:
                return <EtsyAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.WALMART:
                return <WalmartAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.SNAPCHAT:
                return <SnapchatAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.INSTACART:
                return <InstacartAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.TARGET:
                return <TargetAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.REDDIT:
                return <RedditAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.PINTEREST:
                return <PinterestAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            case ChannelEnum.YOUTUBE:
                return <YoutubeAdPreview
                    campaign={campaign}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                />;
            default:
                return <></>;
        }

    };

    useEffect(() => {
        fetchCampaign()
    }, [])

    return (
        <>
            {
                backdrop
                    ? <BackDrop open={true} />
                    : renderAdPreview()
            }
        </>
    );
}
