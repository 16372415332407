import { AttachMoneyOutlined } from "@mui/icons-material";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import PreviewIcon from '@mui/icons-material/Preview';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../service/AxiosInterceptor";
import BackDrop from "../../util/BackDrop";
import { navy } from "../../util/Color";
import HoverSocialCard from "./HoverSocialCard";
import React from "react";
import { convertCentsToDollars } from "../../util/Util";
const Dashboard = () => {

  const theme = useTheme();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    instance
      .get("/dashboard")
      .then((response) => {
        setData(response?.data);
      })
      .finally(
        () => {
          setLoading(false)
        }
      )
  }, []);

  const navigate = useNavigate();

  const [range, setRange] = React.useState('all');
  const handleChange = (event: SelectChangeEvent) => {
    setRange(event.target.value as string);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: "100%"
  }));

  return (
    <>
      {loading ?
        <BackDrop open={true} />
        :
        <Box width={'100%'}>
          <Grid
            container
            mt={7}
          >
            {/* <Grid item my={1} sx={{ width: "100%" }}
            >
              <Typography
                fontWeight={"bold"}
                sx={{ flex: "1 1 100%" }}
                id="tableTitle"
                component="div"
                px={2}
                py={0.75}
                border={"solid 0.5px silver"}
                bgcolor={theme.palette.primary.main}
                color={"white"}
              >
                Campaigns Summary
              </Typography>
            </Grid> */}
            <Divider />
            <Grid
              item
              xs={12}
              justifyContent="flex-end"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="range-date-select-standard-label">Date Range</InputLabel>
                <Select
                  labelId="range-date-select-standard-label"
                  id="range-date-select-standard"
                  value={range}
                  onChange={handleChange}
                  label="Range Date"
                >
                  <MenuItem value={7}>Last 7 Days</MenuItem>
                  <MenuItem value={30}>Last 30 Days</MenuItem>
                  <MenuItem value={6}>Last 6 Months</MenuItem>
                  <MenuItem value={1}>Last 1 Year</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={2} ml={2}
            justifyContent={"left"}
            alignItems="left"
          >
            <Typography fontWeight={"bold"} color={navy}>
              Google Ads
            </Typography>
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={0}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/payments", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Ad Budget"
                  secondary={convertCentsToDollars(data?.google_ad.budget)}
                  iconPrimary={AttachMoneyOutlined}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Impressions"
                  secondary={data?.google_ad.impression ?? 0}
                  iconPrimary={PreviewIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Clicks"
                  secondary={data?.google_ad.click ?? 0}
                  iconPrimary={AdsClickIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Average CPC"
                  secondary={convertCentsToDollars(data?.google_ad.cpc)}
                  iconPrimary={ReceiptLongIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={7} ml={2}
            justifyContent={"left"}
            alignItems="left"
          >
            <Typography fontWeight={"bold"} color={navy}>
              Facebook Ads
            </Typography>
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={0}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/payments", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Ad Budget"
                  secondary={convertCentsToDollars(data?.facebook_ad.budget)}
                  iconPrimary={AttachMoneyOutlined}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/cards", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Impressions"
                  secondary={data?.facebook_ad.impression ?? 0}
                  iconPrimary={PreviewIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/charges", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Clicks"
                  secondary={data?.facebook_ad.click ?? 0}
                  iconPrimary={AdsClickIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Average CPC"
                  secondary={convertCentsToDollars(data?.facebook_ad.cpc)}
                  iconPrimary={ReceiptLongIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={7} ml={2}
            justifyContent={"left"}
            alignItems="left"
          >
            <Typography fontWeight={"bold"} color={navy}>
              Instagram Ads
            </Typography>
          </Grid>

          <Divider />
          <Grid container spacing={2} mt={0}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/payments", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Ad Budget"
                  secondary={convertCentsToDollars(data?.instagram_ad.budget)}
                  iconPrimary={AttachMoneyOutlined}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Impressions"
                  secondary={data?.instagram_ad.impression ?? 0}
                  iconPrimary={PreviewIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Clicks"
                  secondary={data?.instagram_ad.click ?? 0}
                  iconPrimary={AdsClickIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Average CPC"
                  secondary={convertCentsToDollars(data?.instagram_ad.cpc)}
                  iconPrimary={ReceiptLongIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={7} ml={2}
            justifyContent={"left"}
            alignItems="left"
          >
            <Typography fontWeight={"bold"} color={navy}>
              TikTok Ads
            </Typography>
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={0}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/payments", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Ad Budget"
                  secondary={convertCentsToDollars(data?.tiktok_ad.budget)}
                  iconPrimary={AttachMoneyOutlined}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Impressions"
                  secondary={data?.tiktok_ad.impression ?? 0}
                  iconPrimary={PreviewIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Clicks"
                  secondary={data?.tiktok_ad.click ?? 0}
                  iconPrimary={AdsClickIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Average CPC"
                  secondary={convertCentsToDollars(data?.tiktok_ad.cpc)}
                  iconPrimary={ReceiptLongIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={7} ml={2}
            justifyContent={"left"}
            alignItems="left"
          >
            <Typography fontWeight={"bold"} color={navy}>
              LinkedIn Ads
            </Typography>
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={0}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/payments", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Ad Budget"
                  secondary={convertCentsToDollars(data?.linkedin_ad.budget)}
                  iconPrimary={AttachMoneyOutlined}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Impressions"
                  secondary={data?.linkedin_ad.impression ?? 0}
                  iconPrimary={PreviewIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Total Clicks"
                  secondary={data?.linkedin_ad.click ?? 0}
                  iconPrimary={AdsClickIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Item style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/campaigns", { replace: true });
                }}>
                <HoverSocialCard
                  primary="Average CPC"
                  secondary={convertCentsToDollars(data?.linkedin_ad.cpc)}
                  iconPrimary={ReceiptLongIcon}
                  color={theme.palette.primary.main}
                /></Item>
            </Grid>
          </Grid>


        </Box >
      }
    </>
  );
};

export default Dashboard;
