import { Box, Button, Card, CardContent, CardHeader, Container, Grid, Link, Stack, TextField, Typography } from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


const InitiateForgotPassword = () => {

  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false);

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          sx={{ width: { xs: '100%', sm: '50%' } }}>
          <CardHeader
            title="Reset Password"
            titleTypographyProps={{
              textAlign: "center",
              padding: 2,
              fontSize: "20px",
            }}
            subheader="Please provide the email address that you use to sign in to the
            application"
            subheaderTypographyProps={{ textAlign: "center" }}
          />

          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {submitted ? (
                <Typography m={5} p={3} variant="body1">
                  We just sent an email to reset your password.
                  Please check out your inbox and move forward to reset your password.
                </Typography>
              ) : (
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email().required("Email is required"),
                  })}
                  onSubmit={async (
                    values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                  ) => {
                    axios
                      .post(
                        process.env.REACT_APP_ADB_BASE_API_URL +
                        "/initiate-forgot-password",
                        {
                          email: values.email,
                        }
                      )
                      .then((response) => {
                        setSubmitted(true);
                      })
                      .catch((error) => {
                        toast.error(error.response.data.message || "Error occurred!")
                        setStatus({ success: false });
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} py={1}>
                        <Stack spacing={0.5}>
                          <TextField
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            autoComplete="off"
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                        >
                          <Button
                            disabled={
                              isSubmitting || Object.keys(errors).length !== 0
                            }
                            type="submit"
                            variant="contained"
                            size="small"
                          >
                            submit
                          </Button>
                        </Stack>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )
              }
            </Grid >
          </CardContent>
          <Grid container m={5}>
            <Grid item xs>
              <Link
                style={{ cursor: "pointer" }}
                variant="body2"
                onClick={() => {
                  navigate("/signin", {
                    replace: true,
                  });
                }}
              >
                Sign In
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Container>

  );
};

export default InitiateForgotPassword;
