import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import NotFound from './component/404/NotFound';
import CampaignStepper from "./component/Campaign/CampaignStepper";
import CampaignTable from "./component/Campaign/CampaignTable";
import Dashboard from "./component/Dashboard/Dashboard";
import Footer from "./component/Footer/Footer";
import SideMenu from './component/Menu/SideMenu';
import Payment from "./component/Payment/PaymentTable";
import Referral from "./component/Referral/Referral";
import Setting from './component/Setting/Setting';
import Support from './component/Support/Support';
import InitiateForgotPassword from './component/User/InitiateForgotPassword';
import ResetPassword from './component/User/ResetPassword';
import SetPassword from './component/User/SetPassword';
import SignIn from './component/User/SignIn';
import Users from "./component/User/UserTable";
import { AuthProvider } from './provider/AuthProvider';
import { StepperProvider } from "./provider/StepperProvider";
import { ProtectedRoute } from './util/ProtectedRoute';
import IntercomComponent from "./component/Intercom/IntercomComponent";
import SignUp from "./component/User/SignUp";
import SignUpCompleted from "./component/User/SignUpCompleted";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <StepperProvider >
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
            }}
          />
          <Box
            sx={{
              // paddingY: 5,
              // paddingX: { xs: 2, sm: 3, md: 4, lg: 6, xl: 8 },
              // paddingRight: { xs: 4, sm: 4, md: 4, lg: 6, xl: 8 },
              display: "flex",
            }}
          >
            <SideMenu />
            <Box
              sx={{
                paddingY: 5,
                paddingX: { xs: 2, sm: 3, md: 4, lg: 6, xl: 8 },
                width: '100%'
              }}
            >
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/campaigns" element={<CampaignTable />} />
                  <Route path="/campaign-stepper" element={
                    <CampaignStepper />
                  } />
                  <Route path="/payments" element={<Payment />} />
                  {/* <Route path="/payment_intent" element={<Payment />} /> */}
                  {/* <Route path="/business" element={<Business />} /> */}
                  {/* <Route path="/ad" element={<Ad />} /> */}
                  {/* <Route path="/creatives" element={<Creative />} /> */}
                  <Route path="/settings" element={<Setting />} />
                  {/* <Route path="/support" element={<Support />} /> */}
                  {/* <Route path="/tracking" element={<Tracking />} /> */}
                  <Route path="/referrals" element={<Referral />} />
                </Route>
                <Route path="/app/onboard/set-password" element={<SetPassword />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signup-completed" element={<SignUpCompleted />} />
                <Route path="/initiate-forgot-password" element={<InitiateForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
          </Box>
          <Box
            display={"column"}
            m={0}
            position={"fixed"}
            bottom={0}
            width={"100vw"}
          >
            <Footer />
          </Box>
        </StepperProvider>
        <IntercomComponent />
      </AuthProvider >
    </BrowserRouter>
  );
}

