import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Grid,
  TextField,
  Toolbar
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import instance from "../../service/AxiosInterceptor";

import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function AddReferralModal({ fetchReferrals, users }: any) {

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email().required(),
  });

  const submitHandler = (values: any) => {

    setLoading(true)

    instance
      .post("/referrals", {
        email: values.email,
      })
      .then((response) => response.data)
      .then((data) => {
        handleClose();
        toast.success("Referral sent!");
        fetchReferrals();
      })
      .catch((error: any) => {
      }).finally(
        () => {
          setLoading(false)
        }
      )
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const { errors, touched, values, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        // startIcon={<AddCircleOutlineIcon />}
        size="small"
        sx={{ width: { xs: '100%', sm: 150, fontWeight: "bold" } }}
      >
        Send Referral
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              Send Referral
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit} id="add-business-form">
              <Grid container spacing={3} justifyContent="center" my={5}>
                <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>

                  <Grid item xs={12} mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                    <TextField
                      fullWidth
                      margin="dense"
                      size="small"
                      variant="standard"
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions style={{ padding: "1rem 2rem" }}>
          <LoadingButton
            loading={loading}
            autoFocus
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            send
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
