import ImageIcon from '@mui/icons-material/Image';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@mui/material";
import React from 'react';
import EmptyTable from '../../../util/EmptyTable';

const GoogleSearchAdPreview = (props: any) => {

    const theme = useTheme()
    React.useEffect(() => {
    }, []);

    return (
        <>
            {
                <Grid container
                    mt={2}
                    justifyContent={"center"}
                    alignItems="center"
                >
                    <Card sx={{ width: { xs: '100%' } }}>
                        <CardHeader
                            title="Google Search Ad Preview"
                            titleTypographyProps={{
                                textAlign: "left",
                                fontSize: "1.1rem",
                                color: "white",
                            }}
                            subheader=""
                            subheaderTypographyProps={{ textAlign: "left" }}
                            style={{ backgroundColor: theme.palette.primary.main, height: "2.2rem" }}
                        />
                        <CardContent>
                            <Grid
                                container
                                my={2}
                                spacing={2}
                                justifyContent="center"
                                alignItems="center">
                                {
                                    props.campaign?.ads[0].content.length > 0 ?
                                        props.campaign?.ads[0].content?.map((c: any, index: number) =>
                                            <Grid item xs={3.5} key={index}>
                                                <Card sx={{ border: "solid 0.5px silver", minHeight: "14rem" }}>
                                                    <CardContent>
                                                        <Typography sx={{ fontSize: 14 }} color="text.dark" fontWeight={"bold"}>
                                                            Sponsored
                                                        </Typography>
                                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: '0' }} >
                                                            <ListItem style={{
                                                                margin: "0px",
                                                                padding: "0"
                                                            }}>
                                                                <ListItemAvatar sx={{
                                                                    margin: "0",
                                                                    minWidth: "40px"
                                                                }}>
                                                                    <Avatar sx={{ width: 30, height: 30 }}
                                                                    >
                                                                        <ImageIcon sx={{
                                                                        }} />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={props.campaign.ads[0].company}
                                                                    secondary={props.campaign.ads[0].website}
                                                                    sx={{
                                                                        padding: "0"
                                                                    }} />
                                                            </ListItem>
                                                        </List>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography sx={{ color: "#180EA4" }} variant="h6">
                                                                    {c.header}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography sx={{ mb: 1.5 }} >
                                                                    {c.description}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        {/* <Button size="small">Learn More</Button> */}
                                                    </CardActions>
                                                </Card>

                                            </Grid>
                                        ) :
                                        <EmptyTable message="No ad content found to preview. 
                                            Don't worry, we've got you covered. 
                                            You can continue, and we'll generate them for you." />
                                }
                            </Grid>

                            <Divider style={{ margin: 20 }} />

                            <Grid container mt={2}>
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            props.handleBack()
                                        }}
                                    >
                                        <Typography fontWeight={"bold"} fontSize={13}>
                                            back
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            props.handleNext()
                                        }}
                                    >
                                        <Typography fontWeight={"bold"} fontSize={13}>
                                            confirm and continue
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid >
            }
        </>
    );
}

export default GoogleSearchAdPreview