import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import {
    AppBar,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Toolbar
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


export default function FacebookAdWorkSampleModal(props: any) {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Typography variant="subtitle2"
                onClick={handleClickOpen}
                style={{ cursor: "pointer" }}>
                Click <span style={{ color: theme.palette.primary.main }}>here</span> to see our previous designed banners.
            </Typography>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
                maxWidth={"lg"}
                fullWidth={true}
            // fullScreen={true}

            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                            Facebook Ad Samples
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        {Array.from({ length: 5 }, (_, i) => i + 1).map((index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} md={6} display="flex" justifyContent="center">
                                    <video width="640" height="360" controls>
                                        <track
                                            src="path/to/your/captions.vtt"
                                            kind="captions"
                                            srcLang="en"
                                            label="English captions"
                                        />
                                        <source src={`https://video-creatives.s3.amazonaws.com/video-${index}.mp4`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleClose}
                        sx={{ m: 1.5 }}
                    >
                        ok
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
}
